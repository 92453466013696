import { BookA, Computer, DatabaseZap, LineChart, LockIcon, Package, Search, ShoppingCart, Truck, Wrench } from "lucide-react";
import { IExtendedMenuSection } from "./types";

export const getMenuElements = (): IExtendedMenuSection[] => {
    return [
        {
            active: true,
            title: "Sklep",
            icon: ShoppingCart,
            opened: true,
            elements: [
                {
                    title: "Zamówienia",
                    route: "/shop/orders/index",
                    icon: ShoppingCart,
                },

                {
                    title: "Szukaj referencji",
                    route: "/shop/references/find",
                    icon: Search,
                },

                // {
                //   title: "Towary",
                //   route: "/products/index",
                // },
                {
                    title: "Towary",
                    route: "/erp/products/articles",
                    isNext: true,
                },
                {
                    title: "Kategorie",
                    route: "/shop/categories/index",
                },
                {
                    title: "Cechy",
                    route: "/products/properties",
                },
                // {
                //   title: "Partnerzy",
                //   route: "/shop/partners/index",
                // },
                {
                    title: "Schematy",
                    route: "/shop/products/schemes",
                },
                {
                    title: "Producenci",
                    route: "/shop/producers",
                },
                {
                    title: "Oferty",
                    route: "/crm/customers/offers",
                    isNext: true,
                },
            ],
        },
        {
            active: true,
            title: "CMS",
            icon: BookA,
            opened: true,
            elements: [
                {
                    title: "Strona główna",
                    route: "/cms/main/mainPage",
                },
                {
                    title: "Centrum zdarzeń",
                    route: "/cms/main/index",
                },
                {
                    title: "Zespół",
                    route: "/erp/employees/profiles",
                    isNext: true,
                },

                // {
                //     title: 'Kontakt',
                //     route: '/cms/team/index',
                // },
                // {
                //     title: 'Zarz\u0105dzanie kontaktami',
                //     route: '/erp/employees/contacts-management',
                // },
                {
                    title: "Dystrybutorzy",
                    route: "/erp/distributors",
                },
                {
                    title: "Strony",
                    route: "/cms/pages/index",
                },
                {
                    title: "Języki",
                    route: "/translations/languages/index",
                },
                {
                    title: "Tłumaczenia [teksty]",
                    route: "/translations/static/index",
                },
                {
                    title: "Tłumaczenia [obiekty]",
                    route: "/translations/objects/index",
                },
            ],
        },
        {
            active: true,
            title: "CRM",
            icon: LineChart,
            opened: false,
            elements: [
                {
                    title: "Kontrahenci",
                    route: "/customers/index",
                },
                {
                    title: "Zadania",
                    route: "/tasks/index",
                },
                {
                    title: "Zadania todo",
                    route: "/tasks-light/index",
                },
                {
                    title: "Rezerwacje",
                    route: "/cars/index",
                },
                {
                    title: "Raporty",
                    route: "/erp/reports/turnover",
                    isNext: true,
                },
                {
                    title: "Raporty - zapytania",
                    route: "/reports/queries",
                },
                {
                    title: "Intranet",
                    route: "/intranet/index",
                },
                {
                    title: "Eksport Faktur",
                    route: "/exports/invoices",
                },
                {
                    title: "Kolejka Mailowa",
                    route: "/communication/mailqueue/index",
                },
                {
                    title: "Zlecenia transportowe",
                    route: "/transport/pl/order/board",
                },
                {
                    title: "Zlecenia transportowe" + " Italy",
                    route: "/transport/it/order/board",
                },
                {
                    title: "Zlecenia transportowe" + " Espana",
                    route: "/transport/es/order/board",
                },
                {
                    title: "Zlecenia transportowe" + "UK",
                    route: "/transport/gb/order/board",
                },
                {
                    title: "Rozrachunki",
                    route: "/crm/customers/settlement",
                },
                {
                    title: "Produkty - raport cenowy",
                    route: "/erp/products/prince-report",
                },
                {
                    title: "Synchronizacja",
                    route: "/erp/synchronization/scheduler",
                    isNext: true,
                },
                {
                    title: "TecDoc",
                    route: "/tec-doc/export",
                },
                {
                    title: "Export FV IT",
                    route: "/erp/invoices",
                },
                {
                    title: "Webservice test",
                    route: "/erp/falcon/webservice/test",
                },
                {
                    title: "Ustawienia CRM",
                    route: "/erp/settings",
                },
                {
                    title: "Eksporty",
                    route: "/erp/synchronization/exports",
                    isNext: true,
                },
                {
                    title: "Targi",
                    route: "/crm/events/fairs",
                    isNext: true,
                },
            ],
        },
        {
            active: true,
            title: "Reklamacje",
            icon: Wrench,
            opened: false,
            elements: [
                {
                    title: "Reklamacje",
                    route: "/reclaim/index",
                },
                {
                    title: "Lista problemów",
                    route: "/reclaim/problems",
                },
                {
                    title: "Powody nieuznań",
                    route: "/reclaim/reasons",
                },
                {
                    title: "Statystyki",
                    route: "/reclaim/statistic",
                },
                {
                    title: "Statystyki problemów",
                    route: "/reclaim/statistic/problems",
                },
            ],
        },
        {
            active: true,
            title: "Produkcja",
            icon: Package,
            opened: false,
            elements: [
                {
                    title: "Zestawy AS",
                    route: "/production/sets/as",
                },
                {
                    title: "Zestawy UDR",
                    route: "/production/sets/udr",
                },
                {
                    title: "Zestawy Regeneracja",
                    route: "/production/sets/regen",
                },
                {
                    title: "Zestawienia",
                    route: "/production/comparisons",
                },
                {
                    title: "Statystyki - pakowanie",
                    route: "/production/employeeProduction",
                },
                {
                    title: "Oczekujące w sekcji",
                    route: "/production/awaitingInSection",
                },
                {
                    title: "MCP",
                    route: "/production/mcp/starters",
                },
                {
                    title: "Wgraj pliki",
                    route: "/production/import-files",
                },
                {
                    title: "Zlecenia",
                    route: "/production/orders/orders-list",
                },
            ],
        },
        {
            active: true,
            title: "Logistyka",
            icon: Truck,
            opened: false,
            elements: [
                {
                    title: "Automatyczna MM",
                    route: "/erp/logistics/materials-management/move-from-shelf",
                    isNext: true,
                },
            ],
        },
        {
            active: true,
            title: "Dane zewnętrzne",
            icon: DatabaseZap,
            opened: false,
            elements: [
                {
                    title: "Autodoc Crawler",
                    route: "/erp/products/autodoc-crawler",
                    isNext: true,
                },
                {
                    title: "TecDoc Import",
                    route: "/erp/products/tec-doc-importer",
                    isNext: true,
                },
            ],
        },
        {
            active: true,
            title: "Uprawnienia",
            icon: LockIcon,
            opened: false,
            elements: [
                {
                    title: "Użytkownicy",
                    route: "/system/users/list",
                },
                {
                    title: "Grupy dostepu",
                    route: "/access/groups/list",
                },
                {
                    title: "Uprawnienia",
                    route: "/access/points/list",
                },
            ],
        },
        {
            active: true,
            title: "System",
            icon: Computer,
            opened: false,
            elements: [
                {
                    title: "Kolejka",
                    route: "/system/queue",
                    isNext: true,
                },
                {
                    title: "Słowniki",
                    route: "/common/dictionary/list",
                },
                {
                    title: "Developer",
                    route: "/utils/developer/index",
                },
                {
                    title: "Cache",
                    route: "/system/cache",
                    isNext: true,
                },
                {
                    title: "Logi",
                    route: "/system/logs",
                },
                {
                    title: "ORM changes",
                    route: "/utils/developer/orm-changes-index",
                },
            ],
        },
    ];
};
