import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { Uppy } from "@uppy/core";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { Dashboard } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import { useEffect, useRef, useState } from "react";
import { Control, Path, useController } from "react-hook-form";

import { Modal } from "../../../Modal/Modal";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { DisplayFiles } from "./DisplayFiles/DisplayFiles";
import { getApi, queryDeleteFile, queryGetPrevFiles, querySortMedia } from "./Queries";
import { TDefaultFile, TThumbnailObj } from "./Types";

import Cookies from "js-cookie";
// @ts-ignore uppy is not type-safety
import Polish from "@uppy/locales/lib/pl_PL";
import { LockKeyhole, Users } from "lucide-react";

function initUppy(api: string, nameType: string, model: string, privateField: string, access: string) {
    return (
        new Uppy({
            id: "upload files",
            autoProceed: false,
            meta: { nameType: nameType, model: model, private: privateField, access: access },
            allowMultipleUploads: false,
        })
            .use(XHR, {
                endpoint: `${api}/upload`,
                allowedMetaFields: ["name", "nameType", "model", "objectId", "sort", "sortIndex", "private", "access"],
                getResponseError(respText) {
                    return new Error(JSON.parse(respText).error);
                },
            })
            // @ts-ignore uppy is not type-safety
            .use(ThumbnailGenerator)
    );
}

export const FileUploader = <T extends Partial<T>>(props: {
    name: string;
    id: string;
    model: string;
    fileExt?: string[];
    label?: string;
    numberOfFiles?: number;
    private?: boolean;
    access?: string;
    path: Path<T>;
    objectId: string;
    control: Control<T, any>;
    uploadTrigger: boolean;
}) => {
    const api = getApi();
    if (api === undefined) {
        //alert("API is undefined");
        console.log("[FileUploader] API is undefined");
    }
    const [uppy] = useState(() =>
        initUppy(api ? api : "", props.name, props.model, props.private ? "1" : "0", props.access ? props.access : ""),
    );
    const control = useController({ name: props.path, control: props.control });

    const [uploadStatus, setUploadStatus] = useState("waiting");
    const [localFormState, setLocalFormState] = useState(false);

    const [files, setFiles] = useState<TDefaultFile[]>([]);
    const [thumbnails, setThumbnails] = useState<TThumbnailObj[]>([]);

    const [isModalVisible, setModalVisible] = useState(false);
    //const t = useTranslation();
    const t = (key: string) => key;
    const buttonRef = useRef(null);

    // * Get Prev files
    useEffect(() => {
        if (props.id) {
            (async () => {
                const prevFiles: TDefaultFile[] = await queryGetPrevFiles(props.id, props.model, props.name);
                setFiles(prevFiles);
            })();
        }
    }, [props.id]);

    // * Set objectId
    useEffect(() => {
        if (props.objectId) {
            uppy.setMeta({ objectId: props.objectId });
        }
    }, [props.objectId]);

    // * Uppy validation number of files
    useEffect(() => {
        if (props.numberOfFiles && files) {
            if (files.length > 0) {
                uppy.setOptions({
                    onBeforeFileAdded: () => {
                        if (props.numberOfFiles && props.numberOfFiles <= files.length) {
                            uppy.info(t("Za dużo plików"), "info", 2000);
                            return false;
                        }
                    },
                });
            }
            if (files.length < props.numberOfFiles) {
                uppy.setOptions({
                    restrictions: { maxNumberOfFiles: props.numberOfFiles - files.length + uppy.getFiles().length },
                });
            }
        }
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                if (!file.uploaded)
                    uppy.setFileMeta(file.key, { sort: files.map((file) => file.key), sortIndex: index });
            });
        }
    }, [files]);

    // * Start Upload when trigger up
    useEffect(() => {
        if (localFormState) {
            startUploadWithWait();
        }
    }, [localFormState]);

    // * Uppy set restrictions
    useEffect(() => {
        if (props.fileExt && props.fileExt.length > 0) {
            uppy.setOptions({ restrictions: { allowedFileTypes: props.fileExt } });
        }
        if (props.numberOfFiles) {
            uppy.setOptions({
                restrictions: { maxNumberOfFiles: props.numberOfFiles },
            });
        }
    }, [props.fileExt, props.numberOfFiles]);

    // * set thumbnails for new files
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = (file, preview) => {
            setThumbnails((prev) => [...prev, { id: file.id, thumbnail: preview }]);
        };
        uppy.on("thumbnail:generated", handler);
        return () => {
            uppy.off("thumbnail:generated", handler);
        };
    }, []);

    // * add file trigger
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = (file) => {
            setUploadStatus("ready");

            const fileInfo: TDefaultFile = {
                key: file.id,
                name: file.name,
                size: file.size,
                description: "",
                title: "",
                type: file.type,
                uploaded: false,
                path: "",
            };
            setFiles((prev) => [...prev, fileInfo]);

            // * Set headers with JWT token per file
            uppy.setFileState(file.id, {
                xhrUpload: {
                    headers: {
                        Authorization: "Token " + Cookies.get("jwt"),
                    },
                },
            });
            setModalVisible(false);
        };

        uppy.on("file-added", handler);
        return () => {
            uppy.off("file-added", handler);
        };
    }, []);

    // * remove file trigger
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = (file) => {
            setUploadStatus("ready");

            const fileInfo: TDefaultFile = {
                key: file.id,
                name: file.name,
                size: file.size,
                description: "",
                title: "",
                type: file.type,
                uploaded: false,
                path: "",
            };
            setFiles((prev) => deleteFromStatus(prev, fileInfo));
        };

        uppy.on("file-removed", handler);
        return () => {
            uppy.off("file-removed", handler);
        };
    }, []);

    // * trigger submit
    if (props.uploadTrigger !== localFormState && props.objectId) {
        setLocalFormState(props.uploadTrigger);
    }

    const deleteFromStatus = (prev: TDefaultFile[], file: TDefaultFile) => {
        return prev.filter((el) => file.key !== "" && file.key !== el.key);
    };

    // * upload function with wait
    const startUploadWithWait = () => {
        if (uppy.getFiles().length > 0) {
            // * Upload function
            // @ts-ignore uppy is not type-safety
            const uploadHandler = async (res) => {
                setUploadStatus("uploaded");

                console.log(res);

                if (res.failed.length > 0) {
                    // @ts-ignore uppy is not type-safety
                    const errors: string[] = res.failed.map((el) => el["error"]);
                    props.control.setError(props.path, { type: "Upload Err", message: errors.join(" ; ") });
                    return;
                }

                const passVal: TDefaultFile[] = files.map((file) => {
                    if (file.uploaded) {
                        return file;
                    }

                    // @ts-ignore uppy is not type-safety
                    const newVal = res.successful.find((el) => el.id === file.key).response.body.file;
                    if (newVal) {
                        return newVal;
                    }
                });

                control.field.onChange({ target: { value: passVal } });

                setFiles(passVal);

                uppy.cancelAll();
            };

            // * Retry upload
            if (uploadStatus === "uploaded") {
                setUploadStatus("uploading");
                control.field.onChange({ target: { value: "wait" } });

                uppy.retryAll().then(uploadHandler);
            }

            // * New upload
            if (uploadStatus === "ready") {
                setUploadStatus("uploading");
                control.field.onChange({ target: { value: "wait" } });

                uppy.upload().then(uploadHandler);
            }
        }
    };

    // * Delete instant on click
    const deleteFileHandler = (file: TDefaultFile) => {
        if (file.uploaded) {
            queryDeleteFile(file.key).then((isDeleted) => {
                if (isDeleted) {
                    setFiles((prev) => deleteFromStatus(prev, file));
                } else {
                    console.error("Delete Failed");
                }
            });
        } else {
            uppy.removeFile(file.key);
        }
    };

    const moveHandler = async (direction: number, key: string) => {
        const index = files.findIndex((el) => el.key === key);

        if (index === -1) return;

        const newSort = [...files];

        const newIndex = index + direction;

        if (newIndex >= 0 && newIndex < newSort.length) {
            [newSort[index], newSort[newIndex]] = [newSort[newIndex], newSort[index]];
        }

        if (props.id && files[index].uploaded) {
            const isSet = await querySortMedia(
                props.name,
                props.model,
                props.id,
                newSort.filter((el) => el.uploaded).map((el) => el.key),
            );
            if (isSet) {
                setFiles(newSort);
            }
        } else {
            setFiles(newSort);
        }
    };

    return (
        <div className="mx-2 select-none">
            {!!props.label && <div className="inline-block mb-2 text-black ">{props.label}</div>}
            {files && (
                <DisplayFiles
                    data={files}
                    deleteFn={deleteFileHandler}
                    moveHandler={moveHandler}
                    thumbnails={thumbnails}
                    name={props.name}
                />
            )}
            <a
                onClick={() => setModalVisible(true)}
                ref={buttonRef}
                className="cursor-pointer bg-[#005a9d] text-white text-base py-2 px-3 rounded-md"
            >
                <FiPlus className=" mr-[0.15rem]" />
                {t("Dodaj pliki")}
            </a>
            {props.private && <LockKeyhole size={18} color="#7d7d7d" className="ml-2" />}
            {!!props.access && <Users size={18} color="#7d7d7d" className="ml-2" />}

            {control.fieldState.invalid && control.fieldState.error && (
                <div style={{ margin: "1rem", color: "red" }}>{control.fieldState.error.message}</div>
            )}
            {isModalVisible && (
                <Modal
                    show={true}
                    onHide={() => setModalVisible(false)}
                    shadow={false}
                    relativeTo={() => buttonRef.current}
                >
                    <Dashboard
                        data-testid={"dashboard"}
                        uppy={uppy}
                        proudlyDisplayPoweredByUppy={false}
                        hideProgressAfterFinish={true}
                        showProgressDetails={true}
                        hideUploadButton={true}
                        hideCancelButton={true}
                        showRemoveButtonAfterComplete={true}
                        hideRetryButton={true}
                        theme={"dark"}
                        width={300}
                        height={200}
                        locale={Polish}
                    />
                </Modal>
            )}
        </div>
    );
};
