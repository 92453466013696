// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcExports_service.proto" (package "grpcExports_service", syntax proto3)
// tslint:disable
import { StringValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcExports_service.TestConn
 */
export interface TestConn {
    /**
     * @generated from protobuf field: string Host = 1 [json_name = "Host"];
     */
    Host: string;
    /**
     * @generated from protobuf field: string User = 2 [json_name = "User"];
     */
    User: string;
    /**
     * @generated from protobuf field: string Password = 3 [json_name = "Password"];
     */
    Password: string;
    /**
     * @generated from protobuf field: string Port = 4 [json_name = "Port"];
     */
    Port: string;
    /**
     * @generated from protobuf field: string TransportType = 5 [json_name = "TransportType"];
     */
    TransportType: string;
    /**
     * @generated from protobuf field: string FtpDir = 6 [json_name = "FtpDir"];
     */
    FtpDir: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class TestConn$Type extends MessageType<TestConn> {
    constructor() {
        super("grpcExports_service.TestConn", [
            { no: 1, name: "Host", kind: "scalar", localName: "Host", jsonName: "Host", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "User", kind: "scalar", localName: "User", jsonName: "User", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Password", kind: "scalar", localName: "Password", jsonName: "Password", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Port", kind: "scalar", localName: "Port", jsonName: "Port", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "TransportType", kind: "scalar", localName: "TransportType", jsonName: "TransportType", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "FtpDir", kind: "scalar", localName: "FtpDir", jsonName: "FtpDir", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TestConn>): TestConn {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Host = "";
        message.User = "";
        message.Password = "";
        message.Port = "";
        message.TransportType = "";
        message.FtpDir = "";
        if (value !== undefined)
            reflectionMergePartial<TestConn>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestConn): TestConn {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Host = 1 [json_name = "Host"];*/ 1:
                    message.Host = reader.string();
                    break;
                case /* string User = 2 [json_name = "User"];*/ 2:
                    message.User = reader.string();
                    break;
                case /* string Password = 3 [json_name = "Password"];*/ 3:
                    message.Password = reader.string();
                    break;
                case /* string Port = 4 [json_name = "Port"];*/ 4:
                    message.Port = reader.string();
                    break;
                case /* string TransportType = 5 [json_name = "TransportType"];*/ 5:
                    message.TransportType = reader.string();
                    break;
                case /* string FtpDir = 6 [json_name = "FtpDir"];*/ 6:
                    message.FtpDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TestConn, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Host = 1 [json_name = "Host"]; */
        if (message.Host !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Host);
        /* string User = 2 [json_name = "User"]; */
        if (message.User !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.User);
        /* string Password = 3 [json_name = "Password"]; */
        if (message.Password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Password);
        /* string Port = 4 [json_name = "Port"]; */
        if (message.Port !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Port);
        /* string TransportType = 5 [json_name = "TransportType"]; */
        if (message.TransportType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.TransportType);
        /* string FtpDir = 6 [json_name = "FtpDir"]; */
        if (message.FtpDir !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.FtpDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcExports_service.TestConn
 */
export const TestConn = new TestConn$Type();
/**
 * @generated ServiceType for protobuf service grpcExports_service.ExportsService
 */
export const ExportsService = new ServiceType("grpcExports_service.ExportsService", [
    { name: "TestFtpConnection", options: {}, I: TestConn, O: StringValue }
]);
