// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcMonitor.proto" (package "grpcMonitor", syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcMonitor.TaskMonitorQuery
 */
export interface TaskMonitorQuery {
    /**
     * @generated from protobuf field: string Queue = 1 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: string TaskId = 2 [json_name = "TaskId"];
     */
    TaskId: string;
}
/**
 * @generated from protobuf message grpcMonitor.MonitorMessage
 */
export interface MonitorMessage {
    /**
     * @generated from protobuf field: string Type = 1 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Job = 2 [json_name = "Job"];
     */
    Job: string;
    /**
     * @generated from protobuf field: string Message = 3 [json_name = "Message"];
     */
    Message: string;
}
/**
 * @generated from protobuf message grpcMonitor.MonitorInputMessage
 */
export interface MonitorInputMessage {
    /**
     * @generated from protobuf field: string Queue = 1 [json_name = "Queue"];
     */
    Queue: string;
    /**
     * @generated from protobuf field: string TaskID = 2 [json_name = "TaskID"];
     */
    TaskID: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Job = 4 [json_name = "Job"];
     */
    Job: string;
    /**
     * @generated from protobuf field: string Message = 5 [json_name = "Message"];
     */
    Message: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class TaskMonitorQuery$Type extends MessageType<TaskMonitorQuery> {
    constructor() {
        super("grpcMonitor.TaskMonitorQuery", [
            { no: 1, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "TaskId", kind: "scalar", localName: "TaskId", jsonName: "TaskId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TaskMonitorQuery>): TaskMonitorQuery {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queue = "";
        message.TaskId = "";
        if (value !== undefined)
            reflectionMergePartial<TaskMonitorQuery>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskMonitorQuery): TaskMonitorQuery {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Queue = 1 [json_name = "Queue"];*/ 1:
                    message.Queue = reader.string();
                    break;
                case /* string TaskId = 2 [json_name = "TaskId"];*/ 2:
                    message.TaskId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskMonitorQuery, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Queue = 1 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Queue);
        /* string TaskId = 2 [json_name = "TaskId"]; */
        if (message.TaskId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.TaskId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcMonitor.TaskMonitorQuery
 */
export const TaskMonitorQuery = new TaskMonitorQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MonitorMessage$Type extends MessageType<MonitorMessage> {
    constructor() {
        super("grpcMonitor.MonitorMessage", [
            { no: 1, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Job", kind: "scalar", localName: "Job", jsonName: "Job", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MonitorMessage>): MonitorMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Type = "";
        message.Job = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<MonitorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MonitorMessage): MonitorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Type = 1 [json_name = "Type"];*/ 1:
                    message.Type = reader.string();
                    break;
                case /* string Job = 2 [json_name = "Job"];*/ 2:
                    message.Job = reader.string();
                    break;
                case /* string Message = 3 [json_name = "Message"];*/ 3:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MonitorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Type = 1 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Type);
        /* string Job = 2 [json_name = "Job"]; */
        if (message.Job !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Job);
        /* string Message = 3 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcMonitor.MonitorMessage
 */
export const MonitorMessage = new MonitorMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MonitorInputMessage$Type extends MessageType<MonitorInputMessage> {
    constructor() {
        super("grpcMonitor.MonitorInputMessage", [
            { no: 1, name: "Queue", kind: "scalar", localName: "Queue", jsonName: "Queue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "TaskID", kind: "scalar", localName: "TaskID", jsonName: "TaskID", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Job", kind: "scalar", localName: "Job", jsonName: "Job", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MonitorInputMessage>): MonitorInputMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Queue = "";
        message.TaskID = "";
        message.Type = "";
        message.Job = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<MonitorInputMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MonitorInputMessage): MonitorInputMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Queue = 1 [json_name = "Queue"];*/ 1:
                    message.Queue = reader.string();
                    break;
                case /* string TaskID = 2 [json_name = "TaskID"];*/ 2:
                    message.TaskID = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                case /* string Job = 4 [json_name = "Job"];*/ 4:
                    message.Job = reader.string();
                    break;
                case /* string Message = 5 [json_name = "Message"];*/ 5:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MonitorInputMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Queue = 1 [json_name = "Queue"]; */
        if (message.Queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Queue);
        /* string TaskID = 2 [json_name = "TaskID"]; */
        if (message.TaskID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.TaskID);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        /* string Job = 4 [json_name = "Job"]; */
        if (message.Job !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Job);
        /* string Message = 5 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcMonitor.MonitorInputMessage
 */
export const MonitorInputMessage = new MonitorInputMessage$Type();
/**
 * @generated ServiceType for protobuf service grpcMonitor.Track
 */
export const Track = new ServiceType("grpcMonitor.Track", [
    { name: "GetTaskMonitorStream", serverStreaming: true, options: {}, I: TaskMonitorQuery, O: MonitorMessage },
    { name: "TrackTask", clientStreaming: true, options: {}, I: MonitorInputMessage, O: Empty }
]);
