// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProfiles_service.proto" (package "grpcProfiles_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProfilesService } from "./grpcProfiles_service";
import type { ListStringValue } from "./grpcProfiles_service";
import type { StringValue } from "./google/protobuf/wrappers";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Int64Value } from "./google/protobuf/wrappers";
import type { Empty } from "./google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcProfiles_service.ProfilesService
 */
export interface IProfilesServiceClient {
    /**
     * @generated from protobuf rpc: Add1(google.protobuf.Empty) returns (google.protobuf.Int64Value);
     */
    add1(input: Empty, options?: RpcOptions): UnaryCall<Empty, Int64Value>;
    /**
     * @generated from protobuf rpc: Create(google.protobuf.Empty) returns (google.protobuf.StringValue);
     */
    create(input: Empty, options?: RpcOptions): UnaryCall<Empty, StringValue>;
    /**
     * @generated from protobuf rpc: Remove2(google.protobuf.Empty) returns (grpcProfiles_service.ListStringValue);
     */
    remove2(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListStringValue>;
}
/**
 * @generated from protobuf service grpcProfiles_service.ProfilesService
 */
export class ProfilesServiceClient implements IProfilesServiceClient, ServiceInfo {
    typeName = ProfilesService.typeName;
    methods = ProfilesService.methods;
    options = ProfilesService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Add1(google.protobuf.Empty) returns (google.protobuf.Int64Value);
     */
    add1(input: Empty, options?: RpcOptions): UnaryCall<Empty, Int64Value> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Int64Value>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Create(google.protobuf.Empty) returns (google.protobuf.StringValue);
     */
    create(input: Empty, options?: RpcOptions): UnaryCall<Empty, StringValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, StringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Remove2(google.protobuf.Empty) returns (grpcProfiles_service.ListStringValue);
     */
    remove2(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListStringValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ListStringValue>("unary", this._transport, method, opt, input);
    }
}
