// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcServices.proto" (package "grpcServices", syntax proto3)
// tslint:disable
import { ScheduleService } from "./grpcServices";
import type { RunJobInput } from "./grpcServices";
import type { ListStringValue } from "./grpcServices";
import type { CronNextInput } from "./grpcServices";
import type { JobList } from "./grpcServices";
import type { JobInfo } from "./grpcServices";
import type { JobInput } from "./grpcServices";
import type { SchedulerTableResult } from "./grpcServices";
import type { QueryData } from "./grpcServices";
import type { GetJobInput } from "./grpcServices";
import { QueueServiceTaskChain } from "./grpcServices";
import type { ChainDef } from "./grpcServices";
import { QueueService } from "./grpcServices";
import type { ListPointerTaskInfoValue } from "./grpcServices";
import type { Int64Value } from "./google/protobuf/wrappers";
import type { QueueServerInfo } from "./grpcServices";
import type { Int32Value } from "./google/protobuf/wrappers";
import type { MapStringQueueDescriptionValue } from "./grpcServices";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { CancelTaskInput } from "./grpcServices";
import type { ListGetQueueMessRowValue } from "./grpcServices";
import type { GetQueueMessInput } from "./grpcServices";
import type { ListPointerQueueInfoValue } from "./grpcServices";
import type { TaskStreamMessage } from "./grpcServices";
import type { GetTasksListInput } from "./grpcServices";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AssetsService } from "./grpcServices";
import type { StringValue } from "./google/protobuf/wrappers";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DirInfo } from "./grpcServices";
import type { Empty } from "./google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcServices.AssetsService
 */
export interface IAssetsServiceClient {
    /**
     * @generated from protobuf rpc: GetQueueDirInfo(google.protobuf.Empty) returns (grpcServices.DirInfo);
     */
    getQueueDirInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, DirInfo>;
    /**
     * @generated from protobuf rpc: TEst(google.protobuf.StringValue) returns (google.protobuf.StringValue);
     */
    tEst(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, StringValue>;
    /**
     * @generated from protobuf rpc: CleanQueueDirInfo(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    cleanQueueDirInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
}
/**
 * @generated from protobuf service grpcServices.AssetsService
 */
export class AssetsServiceClient implements IAssetsServiceClient, ServiceInfo {
    typeName = AssetsService.typeName;
    methods = AssetsService.methods;
    options = AssetsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetQueueDirInfo(google.protobuf.Empty) returns (grpcServices.DirInfo);
     */
    getQueueDirInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, DirInfo> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, DirInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TEst(google.protobuf.StringValue) returns (google.protobuf.StringValue);
     */
    tEst(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, StringValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, StringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CleanQueueDirInfo(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    cleanQueueDirInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service grpcServices.QueueService
 */
export interface IQueueServiceClient {
    /**
     * @generated from protobuf rpc: GetTasksListStream(grpcServices.GetTasksListInput) returns (stream grpcServices.TaskStreamMessage);
     */
    getTasksListStream(input: GetTasksListInput, options?: RpcOptions): ServerStreamingCall<GetTasksListInput, TaskStreamMessage>;
    /**
     * @generated from protobuf rpc: GetQueueServerInfo(google.protobuf.Empty) returns (grpcServices.ListPointerQueueInfoValue);
     */
    getQueueServerInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListPointerQueueInfoValue>;
    /**
     * @generated from protobuf rpc: GetQueueErrors(grpcServices.GetQueueMessInput) returns (grpcServices.ListGetQueueMessRowValue);
     */
    getQueueErrors(input: GetQueueMessInput, options?: RpcOptions): UnaryCall<GetQueueMessInput, ListGetQueueMessRowValue>;
    /**
     * @generated from protobuf rpc: CancelTask(grpcServices.CancelTaskInput) returns (google.protobuf.BoolValue);
     */
    cancelTask(input: CancelTaskInput, options?: RpcOptions): UnaryCall<CancelTaskInput, BoolValue>;
    /**
     * @generated from protobuf rpc: DeleteQueue(google.protobuf.StringValue) returns (google.protobuf.BoolValue);
     */
    deleteQueue(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, BoolValue>;
    /**
     * @generated from protobuf rpc: GetQueuesInfo(google.protobuf.Empty) returns (grpcServices.MapStringQueueDescriptionValue);
     */
    getQueuesInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, MapStringQueueDescriptionValue>;
    /**
     * @generated from protobuf rpc: GetQueueServerInfoStream(google.protobuf.Int32Value) returns (stream grpcServices.QueueServerInfo);
     */
    getQueueServerInfoStream(input: Int32Value, options?: RpcOptions): ServerStreamingCall<Int32Value, QueueServerInfo>;
    /**
     * @generated from protobuf rpc: PausePlay(google.protobuf.StringValue) returns (google.protobuf.BoolValue);
     */
    pausePlay(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, BoolValue>;
    /**
     * @generated from protobuf rpc: ClearQueue(google.protobuf.StringValue) returns (google.protobuf.Int64Value);
     */
    clearQueue(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, Int64Value>;
    /**
     * @generated from protobuf rpc: GetTasksList(grpcServices.GetTasksListInput) returns (grpcServices.ListPointerTaskInfoValue);
     */
    getTasksList(input: GetTasksListInput, options?: RpcOptions): UnaryCall<GetTasksListInput, ListPointerTaskInfoValue>;
    /**
     * @generated from protobuf rpc: GetQueueLogs(grpcServices.GetQueueMessInput) returns (grpcServices.ListGetQueueMessRowValue);
     */
    getQueueLogs(input: GetQueueMessInput, options?: RpcOptions): UnaryCall<GetQueueMessInput, ListGetQueueMessRowValue>;
}
/**
 * @generated from protobuf service grpcServices.QueueService
 */
export class QueueServiceClient implements IQueueServiceClient, ServiceInfo {
    typeName = QueueService.typeName;
    methods = QueueService.methods;
    options = QueueService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetTasksListStream(grpcServices.GetTasksListInput) returns (stream grpcServices.TaskStreamMessage);
     */
    getTasksListStream(input: GetTasksListInput, options?: RpcOptions): ServerStreamingCall<GetTasksListInput, TaskStreamMessage> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTasksListInput, TaskStreamMessage>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQueueServerInfo(google.protobuf.Empty) returns (grpcServices.ListPointerQueueInfoValue);
     */
    getQueueServerInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, ListPointerQueueInfoValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ListPointerQueueInfoValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQueueErrors(grpcServices.GetQueueMessInput) returns (grpcServices.ListGetQueueMessRowValue);
     */
    getQueueErrors(input: GetQueueMessInput, options?: RpcOptions): UnaryCall<GetQueueMessInput, ListGetQueueMessRowValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetQueueMessInput, ListGetQueueMessRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelTask(grpcServices.CancelTaskInput) returns (google.protobuf.BoolValue);
     */
    cancelTask(input: CancelTaskInput, options?: RpcOptions): UnaryCall<CancelTaskInput, BoolValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelTaskInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQueue(google.protobuf.StringValue) returns (google.protobuf.BoolValue);
     */
    deleteQueue(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, BoolValue> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQueuesInfo(google.protobuf.Empty) returns (grpcServices.MapStringQueueDescriptionValue);
     */
    getQueuesInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, MapStringQueueDescriptionValue> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, MapStringQueueDescriptionValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQueueServerInfoStream(google.protobuf.Int32Value) returns (stream grpcServices.QueueServerInfo);
     */
    getQueueServerInfoStream(input: Int32Value, options?: RpcOptions): ServerStreamingCall<Int32Value, QueueServerInfo> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Int32Value, QueueServerInfo>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PausePlay(google.protobuf.StringValue) returns (google.protobuf.BoolValue);
     */
    pausePlay(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, BoolValue> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearQueue(google.protobuf.StringValue) returns (google.protobuf.Int64Value);
     */
    clearQueue(input: StringValue, options?: RpcOptions): UnaryCall<StringValue, Int64Value> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, Int64Value>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTasksList(grpcServices.GetTasksListInput) returns (grpcServices.ListPointerTaskInfoValue);
     */
    getTasksList(input: GetTasksListInput, options?: RpcOptions): UnaryCall<GetTasksListInput, ListPointerTaskInfoValue> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTasksListInput, ListPointerTaskInfoValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQueueLogs(grpcServices.GetQueueMessInput) returns (grpcServices.ListGetQueueMessRowValue);
     */
    getQueueLogs(input: GetQueueMessInput, options?: RpcOptions): UnaryCall<GetQueueMessInput, ListGetQueueMessRowValue> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetQueueMessInput, ListGetQueueMessRowValue>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service grpcServices.QueueServiceTaskChain
 */
export interface IQueueServiceTaskChainClient {
    /**
     * @generated from protobuf rpc: ChainReport(grpcServices.ChainDef) returns (google.protobuf.BoolValue);
     */
    chainReport(input: ChainDef, options?: RpcOptions): UnaryCall<ChainDef, BoolValue>;
}
/**
 * @generated from protobuf service grpcServices.QueueServiceTaskChain
 */
export class QueueServiceTaskChainClient implements IQueueServiceTaskChainClient, ServiceInfo {
    typeName = QueueServiceTaskChain.typeName;
    methods = QueueServiceTaskChain.methods;
    options = QueueServiceTaskChain.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ChainReport(grpcServices.ChainDef) returns (google.protobuf.BoolValue);
     */
    chainReport(input: ChainDef, options?: RpcOptions): UnaryCall<ChainDef, BoolValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ChainDef, BoolValue>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service grpcServices.ScheduleService
 */
export interface IScheduleServiceClient {
    /**
     * @generated from protobuf rpc: RemoveJob(grpcServices.GetJobInput) returns (google.protobuf.BoolValue);
     */
    removeJob(input: GetJobInput, options?: RpcOptions): UnaryCall<GetJobInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetScedulerTable(grpcServices.QueryData) returns (grpcServices.SchedulerTableResult);
     */
    getScedulerTable(input: QueryData, options?: RpcOptions): UnaryCall<QueryData, SchedulerTableResult>;
    /**
     * @generated from protobuf rpc: AddNewJob(grpcServices.JobInput) returns (google.protobuf.BoolValue);
     */
    addNewJob(input: JobInput, options?: RpcOptions): UnaryCall<JobInput, BoolValue>;
    /**
     * @generated from protobuf rpc: UpdateJob(grpcServices.JobInput) returns (google.protobuf.BoolValue);
     */
    updateJob(input: JobInput, options?: RpcOptions): UnaryCall<JobInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetJob(grpcServices.GetJobInput) returns (grpcServices.JobInfo);
     */
    getJob(input: GetJobInput, options?: RpcOptions): UnaryCall<GetJobInput, JobInfo>;
    /**
     * @generated from protobuf rpc: GetJobList(google.protobuf.Empty) returns (grpcServices.JobList);
     */
    getJobList(input: Empty, options?: RpcOptions): UnaryCall<Empty, JobList>;
    /**
     * @generated from protobuf rpc: Reset(google.protobuf.Empty) returns (google.protobuf.BoolValue);
     */
    reset(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoolValue>;
    /**
     * @generated from protobuf rpc: GetCronNextN(grpcServices.CronNextInput) returns (grpcServices.ListStringValue);
     */
    getCronNextN(input: CronNextInput, options?: RpcOptions): UnaryCall<CronNextInput, ListStringValue>;
    /**
     * @generated from protobuf rpc: RunJobNow(grpcServices.RunJobInput) returns (google.protobuf.BoolValue);
     */
    runJobNow(input: RunJobInput, options?: RpcOptions): UnaryCall<RunJobInput, BoolValue>;
}
/**
 * @generated from protobuf service grpcServices.ScheduleService
 */
export class ScheduleServiceClient implements IScheduleServiceClient, ServiceInfo {
    typeName = ScheduleService.typeName;
    methods = ScheduleService.methods;
    options = ScheduleService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: RemoveJob(grpcServices.GetJobInput) returns (google.protobuf.BoolValue);
     */
    removeJob(input: GetJobInput, options?: RpcOptions): UnaryCall<GetJobInput, BoolValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetJobInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScedulerTable(grpcServices.QueryData) returns (grpcServices.SchedulerTableResult);
     */
    getScedulerTable(input: QueryData, options?: RpcOptions): UnaryCall<QueryData, SchedulerTableResult> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryData, SchedulerTableResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddNewJob(grpcServices.JobInput) returns (google.protobuf.BoolValue);
     */
    addNewJob(input: JobInput, options?: RpcOptions): UnaryCall<JobInput, BoolValue> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateJob(grpcServices.JobInput) returns (google.protobuf.BoolValue);
     */
    updateJob(input: JobInput, options?: RpcOptions): UnaryCall<JobInput, BoolValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJob(grpcServices.GetJobInput) returns (grpcServices.JobInfo);
     */
    getJob(input: GetJobInput, options?: RpcOptions): UnaryCall<GetJobInput, JobInfo> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetJobInput, JobInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobList(google.protobuf.Empty) returns (grpcServices.JobList);
     */
    getJobList(input: Empty, options?: RpcOptions): UnaryCall<Empty, JobList> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, JobList>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Reset(google.protobuf.Empty) returns (google.protobuf.BoolValue);
     */
    reset(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoolValue> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCronNextN(grpcServices.CronNextInput) returns (grpcServices.ListStringValue);
     */
    getCronNextN(input: CronNextInput, options?: RpcOptions): UnaryCall<CronNextInput, ListStringValue> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<CronNextInput, ListStringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RunJobNow(grpcServices.RunJobInput) returns (google.protobuf.BoolValue);
     */
    runJobNow(input: RunJobInput, options?: RpcOptions): UnaryCall<RunJobInput, BoolValue> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<RunJobInput, BoolValue>("unary", this._transport, method, opt, input);
    }
}
