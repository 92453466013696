import { useEffect, useState } from "react";

import { ConfigKey, getEnv } from "@as-pl/env/getEnv";
import { fetchAccessToMenuElements } from "./Queries";
import { SubMenu } from "./SubMenu";
import { getMenuElements } from "./data";
import { IExtendedMenuSection, IMenuElement, IMenuSection } from "./types";
import { getCurrentLang } from "../../translation";

export const useMenuElements = (): IExtendedMenuSection[] => {
    const [elements, setElements] = useState<IExtendedMenuSection[]>([]);

    useEffect(() => {
        (async () => {
            const result = await fetchAccessToMenuElements();

            const sections = getMenuElements()
                .map((section) => {
                    return {
                        ...section,
                        elements: section.elements.filter((menuElement) => {
                            return result[menuElement.route] === true;
                        }),
                    };
                })
                .filter((section) => section.elements.length > 0);

            setElements(sections);
        })();
    }, []);

    return elements;
};

export const NavigateToMenuElement = (element: IMenuElement, routerMethod?: (url: string) => void) => {
    if (element.isNext === true) {
        if (routerMethod) {
            routerMethod("/" + getCurrentLang() + "/" + element.route);
        } else {
            const domain = getEnv(ConfigKey.NEXT_PUBLIC_FRONT_DOMAIN) ?? getEnv(ConfigKey.NEXT_PUBLIC_API_HOST);
            window.location.href = domain + "/next/" + getCurrentLang() + "/" + element.route;
        }
    } else {
        window.location.href = getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) + "/admin/#" + element.route;
    }
};

const layoutStyle = {
    left: "",
};

export const Menu = ({ routerMethod }: { routerMethod?: (url: string) => void }) => {
    const elements = useMenuElements();
    //console.log(import.meta.env.VITE_NEXT_PUBLIC_API_HOST, "env");

    return (
        <div>
            {elements.length > 0 && (
                <div className={layoutStyle.left}>
                    <div className="w-panel-menu" style={{ height: "100%" }}>
                        {/*Need to cast to add new element (is next  to element and check it on click)  */}
                        <SubMenu
                            elements={elements as unknown as IMenuSection[]}
                            onMenuElementClick={(element) => {
                                if (element.isNext === true) {
                                    if (routerMethod) {
                                        routerMethod("/" + getCurrentLang() + "/" + element.route);
                                    } else {
                                        const domain = getEnv(ConfigKey.NEXT_PUBLIC_FRONT_DOMAIN) ?? getEnv(ConfigKey.NEXT_PUBLIC_API_HOST);
                                        window.location.href = domain + "/next/" + getCurrentLang() + "/" + element.route;
                                    }
                                } else {
                                    window.location.href = getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) + "/admin/#" + element.route;
                                }
                            }}
                            mobile={false}
                        />

                        <hr />
                    </div>
                </div>
            )}
        </div>
    );
};
