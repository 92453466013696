"use client";
import { GrpcManager } from "grpc-connect";
import { useEffect, useRef, useState } from "react";
import { CronBoxes } from "./CronBoxes";
import { TExternalField } from "serenity-controls";
import { useTranslation } from "../translation";

export const ScheduleWidget = ({ id, model, externalField }: { id: number; model: string; externalField: TExternalField }) => {
    const t = useTranslation();
    const [cron, setCron] = useState<string>("* * * * *");
    const [active, setActive] = useState<boolean>(false);
    const [next, setNext] = useState<string[]>([]);
    const [last, setLast] = useState<string[]>([]);

    // * no access to panelcontext
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [info, setInfo] = useState<string>("");

    const debounce = useRef(0);

    const client = GrpcManager.QueueManager.getScheduleService();

    // * fetch init data
    useEffect(() => {
        if (id && id > 0 && model && model.length > 0) {
            (async () => {
                try {
                    const data = await client.getJob({ TaskId: id, Model: model });
                    setLast(data.response.Last);
                    setActive(data.response.Active);
                    setCron(data.response.Cron);
                    setNext(data.response.Next);
                } catch (e) {
                    console.error(e);
                    setErrorMsg(e instanceof Error ? e.message : String(e));
                    if (externalField.setError) {
                        externalField.setError(e instanceof Error ? e.message : String(e));
                    }
                }
            })();
        }
    }, []);

    // * send data on trigger on
    useEffect(() => {
        if (externalField.trigger && externalField.objectId) {
            (async () => {
                try {
                    const taskId = parseInt(externalField.objectId as string);
                    const isSuccess = await client.updateJob({ TaskId: taskId, Model: model, Cron: cron, Active: active, Instant: false });
                    if (isSuccess.response && externalField.turnOnSemafor) {
                        externalField.turnOnSemafor();
                    }
                } catch (e) {
                    console.error(e);
                    setErrorMsg(e instanceof Error ? e.message : String(e));
                    if (externalField.setError) {
                        externalField.setError(e instanceof Error ? e.message : String(e));
                    }
                }
            })();
        }
    }, [externalField.trigger]);

    // * get cron ticks
    const queryNextTicks = async (cronStr: string) => {
        return new Promise((_reject) => {
            clearTimeout(debounce.current);
            // @ts-ignore no type for Timeout
            debounce.current = setTimeout(async () => {
                try {
                    const nextT = await client.getCronNextN({ Cron: cronStr, N: 10 });
                    setNext(nextT.response.Value);
                } catch (e) {
                    console.error(e);
                    setErrorMsg(e instanceof Error ? e.message : String(e));
                }
            }, 800);
        });
    };

    // * onChange cron
    const onChangeCron = (cronStr: string) => {
        queryNextTicks(cronStr);
        setCron(cronStr);
        setErrorMsg("");
        setInfo("");
    };

    const runJobNow = async () => {
        try {
            const isSuccess = await client.runJobNow({ TaskId: id, Model: model, Prepare: false });
            if (isSuccess) {
                setInfo(t("Dodano do kolejki"));
            }
        } catch (e) {
            console.error(e);
            setErrorMsg(e instanceof Error ? e.message : String(e));
        }
    };

    return (
        <div className={`relative flex flex-col gap-2 p-1`}>
            <div className="flex items-center space-x-3">
                <div className="w-16">{t("Aktywny")}</div>
                <div>
                    <Btn label={t("Tak")} active={active} onClick={() => setActive(true)} />
                    <Btn label={t("Nie")} active={!active} onClick={() => setActive(false)} />
                </div>
            </div>
            <div className="flex items-center space-x-3">
                <div className="w-16">Cron: </div>
                <CronBoxes cronString={cron} onChange={onChangeCron} />

                {!!id && (
                    <button className="bg-[#337AB7] p-1.5 text-white" onClick={() => runJobNow()}>
                        Uruchom teraz
                    </button>
                )}
                <div className="block h-[22px]">
                    <i className="h-full text-red-600">{errorMsg}</i>
                    <i className="h-full text-green-600">{info}</i> {/* Brak panel context */}
                </div>
            </div>
            <div>
                {next && next.length > 0 && (
                    <>
                        <b>{t("Kolejne 10 wykonań")}:</b>
                        {next.map((el, id) => {
                            return (
                                <p key={id}>
                                    [{id + 1}]{` => `}
                                    {el}
                                </p>
                            );
                        })}
                    </>
                )}
            </div>
            <div>
                {last && last.length > 0 && (
                    <>
                        <b>{t("Poprzednie 5 wykonań")}:</b>
                        {last.map((el, id) => {
                            return (
                                <p key={id}>
                                    [{id + 1}]{` => `}
                                    {el}
                                </p>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

const Btn = ({ label, active, onClick }: { label: string; active: boolean; onClick: () => void }) => {
    return (
        <button
            className={`h-[25px] border border-l-0 border-solid border-gray-400 px-1.5 first:rounded-l-md first:border-l last:rounded-r-md ${active ? "bg-[#005A9D] text-white" : ""}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};
