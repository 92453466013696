// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTecdoc_service.proto" (package "grpcTecdoc_service", syntax proto3)
// tslint:disable
import { BoolValue } from "./google/protobuf/wrappers";
import { StringValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcTecdoc_service.AuthData
 */
export interface AuthData {
    /**
     * @generated from protobuf field: int64 UserId = 1 [json_name = "UserId"];
     */
    UserId: number;
    /**
     * @generated from protobuf field: string Login = 2 [json_name = "Login"];
     */
    Login: string;
}
/**
 * @generated from protobuf message grpcTecdoc_service.MetaData
 */
export interface MetaData {
    /**
     * @generated from protobuf field: grpcTecdoc_service.AuthData AuthData = 1 [json_name = "AuthData"];
     */
    AuthData?: AuthData;
    /**
     * @generated from protobuf field: string Created = 2 [json_name = "Created"];
     */
    Created: string;
}
/**
 * @generated from protobuf message grpcTecdoc_service.Base
 */
export interface Base {
    /**
     * @generated from protobuf field: grpcTecdoc_service.MetaData MetaData = 1 [json_name = "MetaData"];
     */
    MetaData?: MetaData;
    /**
     * @generated from protobuf field: string ChainId = 2 [json_name = "ChainId"];
     */
    ChainId: string;
}
/**
 * @generated from protobuf message grpcTecdoc_service.TecDocApplicationImporterMessage
 */
export interface TecDocApplicationImporterMessage {
    /**
     * @generated from protobuf field: grpcTecdoc_service.Base Base = 1 [json_name = "Base"];
     */
    Base?: Base;
    /**
     * @generated from protobuf field: bool TecDoc = 2 [json_name = "TecDoc"];
     */
    TecDoc: boolean;
    /**
     * @generated from protobuf field: bool Conn = 3 [json_name = "Conn"];
     */
    Conn: boolean;
}
/**
 * @generated from protobuf message grpcTecdoc_service.TecDocImporterMessage
 */
export interface TecDocImporterMessage {
    /**
     * @generated from protobuf field: grpcTecdoc_service.Base Base = 1 [json_name = "Base"];
     */
    Base?: Base;
    /**
     * @generated from protobuf field: bool Start = 2 [json_name = "Start"];
     */
    Start: boolean;
}
/**
 * @generated from protobuf message grpcTecdoc_service.MonitorMessage
 */
export interface MonitorMessage {
    /**
     * @generated from protobuf field: string Type = 1 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Job = 2 [json_name = "Job"];
     */
    Job: string;
    /**
     * @generated from protobuf field: string Message = 3 [json_name = "Message"];
     */
    Message: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class AuthData$Type extends MessageType<AuthData> {
    constructor() {
        super("grpcTecdoc_service.AuthData", [
            { no: 1, name: "UserId", kind: "scalar", localName: "UserId", jsonName: "UserId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "Login", kind: "scalar", localName: "Login", jsonName: "Login", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthData>): AuthData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.UserId = 0;
        message.Login = "";
        if (value !== undefined)
            reflectionMergePartial<AuthData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthData): AuthData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 UserId = 1 [json_name = "UserId"];*/ 1:
                    message.UserId = reader.int64().toNumber();
                    break;
                case /* string Login = 2 [json_name = "Login"];*/ 2:
                    message.Login = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 UserId = 1 [json_name = "UserId"]; */
        if (message.UserId !== 0)
            writer.tag(1, WireType.Varint).int64(message.UserId);
        /* string Login = 2 [json_name = "Login"]; */
        if (message.Login !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Login);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.AuthData
 */
export const AuthData = new AuthData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetaData$Type extends MessageType<MetaData> {
    constructor() {
        super("grpcTecdoc_service.MetaData", [
            { no: 1, name: "AuthData", kind: "message", localName: "AuthData", jsonName: "AuthData", T: () => AuthData },
            { no: 2, name: "Created", kind: "scalar", localName: "Created", jsonName: "Created", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MetaData>): MetaData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Created = "";
        if (value !== undefined)
            reflectionMergePartial<MetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetaData): MetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTecdoc_service.AuthData AuthData = 1 [json_name = "AuthData"];*/ 1:
                    message.AuthData = AuthData.internalBinaryRead(reader, reader.uint32(), options, message.AuthData);
                    break;
                case /* string Created = 2 [json_name = "Created"];*/ 2:
                    message.Created = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTecdoc_service.AuthData AuthData = 1 [json_name = "AuthData"]; */
        if (message.AuthData)
            AuthData.internalBinaryWrite(message.AuthData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string Created = 2 [json_name = "Created"]; */
        if (message.Created !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Created);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.MetaData
 */
export const MetaData = new MetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Base$Type extends MessageType<Base> {
    constructor() {
        super("grpcTecdoc_service.Base", [
            { no: 1, name: "MetaData", kind: "message", localName: "MetaData", jsonName: "MetaData", T: () => MetaData },
            { no: 2, name: "ChainId", kind: "scalar", localName: "ChainId", jsonName: "ChainId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Base>): Base {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ChainId = "";
        if (value !== undefined)
            reflectionMergePartial<Base>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Base): Base {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTecdoc_service.MetaData MetaData = 1 [json_name = "MetaData"];*/ 1:
                    message.MetaData = MetaData.internalBinaryRead(reader, reader.uint32(), options, message.MetaData);
                    break;
                case /* string ChainId = 2 [json_name = "ChainId"];*/ 2:
                    message.ChainId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Base, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTecdoc_service.MetaData MetaData = 1 [json_name = "MetaData"]; */
        if (message.MetaData)
            MetaData.internalBinaryWrite(message.MetaData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string ChainId = 2 [json_name = "ChainId"]; */
        if (message.ChainId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ChainId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.Base
 */
export const Base = new Base$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TecDocApplicationImporterMessage$Type extends MessageType<TecDocApplicationImporterMessage> {
    constructor() {
        super("grpcTecdoc_service.TecDocApplicationImporterMessage", [
            { no: 1, name: "Base", kind: "message", localName: "Base", jsonName: "Base", T: () => Base },
            { no: 2, name: "TecDoc", kind: "scalar", localName: "TecDoc", jsonName: "TecDoc", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "Conn", kind: "scalar", localName: "Conn", jsonName: "Conn", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TecDocApplicationImporterMessage>): TecDocApplicationImporterMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.TecDoc = false;
        message.Conn = false;
        if (value !== undefined)
            reflectionMergePartial<TecDocApplicationImporterMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TecDocApplicationImporterMessage): TecDocApplicationImporterMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTecdoc_service.Base Base = 1 [json_name = "Base"];*/ 1:
                    message.Base = Base.internalBinaryRead(reader, reader.uint32(), options, message.Base);
                    break;
                case /* bool TecDoc = 2 [json_name = "TecDoc"];*/ 2:
                    message.TecDoc = reader.bool();
                    break;
                case /* bool Conn = 3 [json_name = "Conn"];*/ 3:
                    message.Conn = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TecDocApplicationImporterMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTecdoc_service.Base Base = 1 [json_name = "Base"]; */
        if (message.Base)
            Base.internalBinaryWrite(message.Base, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool TecDoc = 2 [json_name = "TecDoc"]; */
        if (message.TecDoc !== false)
            writer.tag(2, WireType.Varint).bool(message.TecDoc);
        /* bool Conn = 3 [json_name = "Conn"]; */
        if (message.Conn !== false)
            writer.tag(3, WireType.Varint).bool(message.Conn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.TecDocApplicationImporterMessage
 */
export const TecDocApplicationImporterMessage = new TecDocApplicationImporterMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TecDocImporterMessage$Type extends MessageType<TecDocImporterMessage> {
    constructor() {
        super("grpcTecdoc_service.TecDocImporterMessage", [
            { no: 1, name: "Base", kind: "message", localName: "Base", jsonName: "Base", T: () => Base },
            { no: 2, name: "Start", kind: "scalar", localName: "Start", jsonName: "Start", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TecDocImporterMessage>): TecDocImporterMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Start = false;
        if (value !== undefined)
            reflectionMergePartial<TecDocImporterMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TecDocImporterMessage): TecDocImporterMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpcTecdoc_service.Base Base = 1 [json_name = "Base"];*/ 1:
                    message.Base = Base.internalBinaryRead(reader, reader.uint32(), options, message.Base);
                    break;
                case /* bool Start = 2 [json_name = "Start"];*/ 2:
                    message.Start = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TecDocImporterMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* grpcTecdoc_service.Base Base = 1 [json_name = "Base"]; */
        if (message.Base)
            Base.internalBinaryWrite(message.Base, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool Start = 2 [json_name = "Start"]; */
        if (message.Start !== false)
            writer.tag(2, WireType.Varint).bool(message.Start);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.TecDocImporterMessage
 */
export const TecDocImporterMessage = new TecDocImporterMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MonitorMessage$Type extends MessageType<MonitorMessage> {
    constructor() {
        super("grpcTecdoc_service.MonitorMessage", [
            { no: 1, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Job", kind: "scalar", localName: "Job", jsonName: "Job", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MonitorMessage>): MonitorMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Type = "";
        message.Job = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<MonitorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MonitorMessage): MonitorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Type = 1 [json_name = "Type"];*/ 1:
                    message.Type = reader.string();
                    break;
                case /* string Job = 2 [json_name = "Job"];*/ 2:
                    message.Job = reader.string();
                    break;
                case /* string Message = 3 [json_name = "Message"];*/ 3:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MonitorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Type = 1 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Type);
        /* string Job = 2 [json_name = "Job"]; */
        if (message.Job !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Job);
        /* string Message = 3 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcTecdoc_service.MonitorMessage
 */
export const MonitorMessage = new MonitorMessage$Type();
/**
 * @generated ServiceType for protobuf service grpcTecdoc_service.TecdocService
 */
export const TecdocService = new ServiceType("grpcTecdoc_service.TecdocService", [
    { name: "ImportLinksFromTecdocDb", serverStreaming: true, options: {}, I: StringValue, O: MonitorMessage },
    { name: "ImportApplicationFromTecDocDb", options: {}, I: TecDocApplicationImporterMessage, O: BoolValue },
    { name: "PrepareFiles", serverStreaming: true, options: {}, I: StringValue, O: MonitorMessage },
    { name: "ImportTecDocDb", options: {}, I: TecDocImporterMessage, O: BoolValue }
]);
