import { useForm, Control, FieldPath, UseFormProps, UseFormReturn, Path, UseFormGetValues } from "react-hook-form";
import { useRef, useState } from "react";
import { TExternalField, TFileTypes } from "./Types";

interface IFieldProps<T extends object, TContext extends object = object> {
    control: Control<T, TContext>;
    name: string;
    readonly: boolean;
}

export const FileTypes: TFileTypes = {
    image: [".png", ".jpg", ".jpeg", ".apng", ".avif", ".webp"],
    document: [".doc", ".docx", ".rtf", ".txt", ".calc", ".xls", ".xlsx", ".ods", ".pdf", ".pages", ".numbers"],
};

export interface ISuperForm<T extends object, TContext extends object = object> extends UseFormReturn<T, TContext> {
    setFormErrors: (errors: string[]) => any;
    setFieldErrors: (name: FieldPath<T>, errors: string[]) => any;
    setReadonly: (readonly: boolean | ((prevState: boolean) => boolean)) => any;
    isReadOnly: boolean;
    field: (name: FieldPath<T>) => IFieldProps<T>;
    formErrors: string[];
    setSubmitting: (state: boolean) => any;
    uploadFields: (name: Path<T>) => any;
    externalField: (id: string) => TExternalField;
    waitSemafor: (objectId: string) => Promise<boolean>;
    handleFileUpload: (objectId: string) => Promise<boolean>;
}

export interface ISerenityFormProps<T extends object> extends UseFormProps<T> {
    isReadOnly?: boolean;
}

export const useSerenityForm = <T extends object>(props?: ISerenityFormProps<T>): ISuperForm<T> => {
    const form = useForm<T>(props);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [isReadOnly, setReadonly] = useState<boolean>(props?.isReadOnly ?? false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [externalTrigger, setExternalTrigger] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<string>("");

    const semafor = useRef<Map<string, boolean>>(new Map<string, boolean>());

    // * Container with Form paths to files inputs
    const pathsFiles = useRef(new Set<string>());

    return {
        ...form,
        setFormErrors: (errors) => {
            setFormErrors(errors);
        },
        formErrors,
        setFieldErrors: (name, errors) => {
            form.setError(name, { types: errors.reduce((p, c, index) => ({ ...p, [index]: c }), {}) });
        },
        setReadonly,
        isReadOnly,
        field: (name) => {
            return {
                control: form.control,
                name,
                readonly: isReadOnly,
            };
        },
        setSubmitting: (submitting: boolean) => {
            setSubmitting(submitting);
        },
        formState: { ...form.formState, isSubmitting },
        uploadFields: (path) => {
            pathsFiles.current.add(path);

            return {
                path,
                objectId: objectId,
                control: form.control,
                uploadTrigger: externalTrigger,
            };
        },
        handleFileUpload: async (objectId) => {
            if (!objectId) return false;

            setObjectId(objectId);

            setExternalTrigger(true);
            const data = await waitForFileUpload(form.getValues, pathsFiles.current);
            if (Array.isArray(data)) {
                setExternalTrigger(false);
                return false;
            } else {
                setExternalTrigger(false);
                return true;
            }
        },
        externalField: (id: string) => {
            semafor.current.set(id, false);
            return {
                objectId,
                trigger: externalTrigger,
                setError: (err: string) => setFormErrors((prev) => [...prev, err]),
                turnOnSemafor: () => semafor.current.set(id, true),
            };
        },
        waitSemafor: async (objectId) => {
            if (!objectId) return false;

            setObjectId(objectId);
            setExternalTrigger(true);

            let counter: number = 0;

            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (counter > 100) {
                        clearInterval(interval);
                        setExternalTrigger(false);
                        resolve(false);
                    }
                    if (Object.values(semafor.current).every((val) => val)) {
                        clearInterval(interval);
                        setExternalTrigger(false);
                        resolve(true);
                    }
                    counter += 1;
                }, 100);
            });
        },
    };
};

async function waitForFileUpload<T extends Partial<T>>(getValues: UseFormGetValues<T>, pathsSet: Set<string>) {
    await new Promise((r) =>
        setTimeout(() => {
            r(undefined);
        }, 100),
    );
    //waiting to uploads progress
    let interval: ReturnType<typeof setInterval>;
    let counter = 0;
    return await new Promise((r) => {
        interval = setInterval(() => {
            const pathsArray = Array.from(pathsSet);
            const unloadedPaths: string[] = [];

            let pass = true;

            pathsArray.map((path) => {
                const val = getValues(path as Path<T>);
                if (val === "wait") {
                    unloadedPaths.push(path);
                    pass = false;
                }
            });
            if (counter >= 100 && !pass) {
                clearInterval(interval);
                r(unloadedPaths);
            }
            if (pass === true) {
                clearInterval(interval);
                r(getValues());
            }
            counter = counter + 1;
        }, 100);
    });
}
