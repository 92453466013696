import Cookies from "js-cookie";
import { $DefaultFileArr } from "./Types";
import { getEnv, ConfigKey } from "@as-pl/env/getEnv";

// TEMP replace it later
export function getApi() {
    return getEnv(ConfigKey.NEXT_PUBLIC_FILES_STORAGE_API);
    // return "http://localhost:8080";
}

export const queryDeleteFile = async (id: string) => {
    try {
        const response = await fetch(`${getApi()}/files/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: "Token " + Cookies.get("jwt"),
            },
        });
        if (!response.ok) {
            return false;
        }
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const queryGetPrevFiles = async (id: string, model: string, name: string) => {
    try {
        const response = await fetch(`${getApi()}/files/${id}/${encodeURI(model)}/${name}`, {
            method: "GET",
            headers: {
                Authorization: "Token " + Cookies.get("jwt"),
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        $DefaultFileArr.parse(data.files);
        return data.files;
    } catch (e) {
        console.error(e);
    }
};

export const queryGetMedia = async (url: string) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Token " + Cookies.get("jwt"),
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        return downloadUrl;
    } catch (e) {
        console.error(e);
    }
};

export const querySortMedia = async (name: string, model: string, objectId: string, filesIds: string[]) => {
    try {
        const formData = new FormData();

        filesIds.forEach((id) => {
            formData.append("sort[]", id);
        });

        const response = await fetch(`${getApi()}/sort/${objectId}/${encodeURI(model)}/${name}`, {
            method: "POST",
            headers: {
                Authorization: "Token " + Cookies.get("jwt"),
            },
            body: formData,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};
