import { z } from "zod";

export const $MediaFile = z.object({
    Chosen: z.string(),
    Created: z.string(),
    CreatedBy: z.string(),
    Description: z.string(),
    File: z.string(),
    FolderID: z.string(),
    ID: z.string(),
    Name: z.string(),
    Path: z.string(),
    Size: z.string(),
    Sort: z.string(),
    Type: z.string(),
    Uid: z.string(),
});

export type TMediaFile = z.infer<typeof $MediaFile>;

export const $DefaultFile = z.object({
    key: z.string(),
    name: z.string(),
    size: z.union([z.number(), z.boolean()]),
    description: z.string(),
    title: z.string(),
    type: z.string(),
    uploaded: z.boolean(),
    path: z.string(),
    access: z.boolean(),
});

export type TDefaultFile = z.infer<typeof $DefaultFile>;

export const $DefaultFileArr = z.array($DefaultFile);

export const $ThumbnailObj = z.object({
    id: z.string(),
    thumbnail: z.string(),
});

export type TThumbnailObj = z.infer<typeof $ThumbnailObj>;
