// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcTecdoc_service.proto" (package "grpcTecdoc_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TecdocService } from "./grpcTecdoc_service";
import type { TecDocImporterMessage } from "./grpcTecdoc_service";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { TecDocApplicationImporterMessage } from "./grpcTecdoc_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { MonitorMessage } from "./grpcTecdoc_service";
import type { StringValue } from "./google/protobuf/wrappers";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcTecdoc_service.TecdocService
 */
export interface ITecdocServiceClient {
    /**
     * @generated from protobuf rpc: ImportLinksFromTecdocDb(google.protobuf.StringValue) returns (stream grpcTecdoc_service.MonitorMessage);
     */
    importLinksFromTecdocDb(input: StringValue, options?: RpcOptions): ServerStreamingCall<StringValue, MonitorMessage>;
    /**
     * @generated from protobuf rpc: ImportApplicationFromTecDocDb(grpcTecdoc_service.TecDocApplicationImporterMessage) returns (google.protobuf.BoolValue);
     */
    importApplicationFromTecDocDb(input: TecDocApplicationImporterMessage, options?: RpcOptions): UnaryCall<TecDocApplicationImporterMessage, BoolValue>;
    /**
     * @generated from protobuf rpc: PrepareFiles(google.protobuf.StringValue) returns (stream grpcTecdoc_service.MonitorMessage);
     */
    prepareFiles(input: StringValue, options?: RpcOptions): ServerStreamingCall<StringValue, MonitorMessage>;
    /**
     * @generated from protobuf rpc: ImportTecDocDb(grpcTecdoc_service.TecDocImporterMessage) returns (google.protobuf.BoolValue);
     */
    importTecDocDb(input: TecDocImporterMessage, options?: RpcOptions): UnaryCall<TecDocImporterMessage, BoolValue>;
}
/**
 * @generated from protobuf service grpcTecdoc_service.TecdocService
 */
export class TecdocServiceClient implements ITecdocServiceClient, ServiceInfo {
    typeName = TecdocService.typeName;
    methods = TecdocService.methods;
    options = TecdocService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ImportLinksFromTecdocDb(google.protobuf.StringValue) returns (stream grpcTecdoc_service.MonitorMessage);
     */
    importLinksFromTecdocDb(input: StringValue, options?: RpcOptions): ServerStreamingCall<StringValue, MonitorMessage> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, MonitorMessage>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ImportApplicationFromTecDocDb(grpcTecdoc_service.TecDocApplicationImporterMessage) returns (google.protobuf.BoolValue);
     */
    importApplicationFromTecDocDb(input: TecDocApplicationImporterMessage, options?: RpcOptions): UnaryCall<TecDocApplicationImporterMessage, BoolValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<TecDocApplicationImporterMessage, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PrepareFiles(google.protobuf.StringValue) returns (stream grpcTecdoc_service.MonitorMessage);
     */
    prepareFiles(input: StringValue, options?: RpcOptions): ServerStreamingCall<StringValue, MonitorMessage> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<StringValue, MonitorMessage>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ImportTecDocDb(grpcTecdoc_service.TecDocImporterMessage) returns (google.protobuf.BoolValue);
     */
    importTecDocDb(input: TecDocImporterMessage, options?: RpcOptions): UnaryCall<TecDocImporterMessage, BoolValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<TecDocImporterMessage, BoolValue>("unary", this._transport, method, opt, input);
    }
}
