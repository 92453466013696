// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProfiles_service.proto" (package "grpcProfiles_service", syntax proto3)
// tslint:disable
import { StringValue } from "./google/protobuf/wrappers";
import { Int64Value } from "./google/protobuf/wrappers";
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcProfiles_service.ListStringValue
 */
export interface ListStringValue {
    /**
     * @generated from protobuf field: repeated string Value = 1 [json_name = "Value"];
     */
    Value: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListStringValue$Type extends MessageType<ListStringValue> {
    constructor() {
        super("grpcProfiles_service.ListStringValue", [
            { no: 1, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListStringValue>): ListStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListStringValue): ListStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.Value[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcProfiles_service.ListStringValue
 */
export const ListStringValue = new ListStringValue$Type();
/**
 * @generated ServiceType for protobuf service grpcProfiles_service.ProfilesService
 */
export const ProfilesService = new ServiceType("grpcProfiles_service.ProfilesService", [
    { name: "Add1", options: {}, I: Empty, O: Int64Value },
    { name: "Create", options: {}, I: Empty, O: StringValue },
    { name: "Remove2", options: {}, I: Empty, O: ListStringValue }
]);
