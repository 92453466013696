// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcExports_service.proto" (package "grpcExports_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ExportsService } from "./grpcExports_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { StringValue } from "./google/protobuf/wrappers";
import type { TestConn } from "./grpcExports_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcExports_service.ExportsService
 */
export interface IExportsServiceClient {
    /**
     * @generated from protobuf rpc: TestFtpConnection(grpcExports_service.TestConn) returns (google.protobuf.StringValue);
     */
    testFtpConnection(input: TestConn, options?: RpcOptions): UnaryCall<TestConn, StringValue>;
}
/**
 * @generated from protobuf service grpcExports_service.ExportsService
 */
export class ExportsServiceClient implements IExportsServiceClient, ServiceInfo {
    typeName = ExportsService.typeName;
    methods = ExportsService.methods;
    options = ExportsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: TestFtpConnection(grpcExports_service.TestConn) returns (google.protobuf.StringValue);
     */
    testFtpConnection(input: TestConn, options?: RpcOptions): UnaryCall<TestConn, StringValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<TestConn, StringValue>("unary", this._transport, method, opt, input);
    }
}
