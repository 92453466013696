import { AArrowDown } from "lucide-react";
import { IMenuElement, NavigateToMenuElement, useMenuElements } from "../Menu";
import { useEffect, useRef, useState } from "react";

const LayerMenu = ({ routerMethod, onSelect }: { routerMethod?: (url: string) => void; onSelect: () => void }) => {
    const input = useRef<HTMLInputElement>(null);
    const elements = useMenuElements();

    const [filteredElements, setFilteredElements] = useState(elements);
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState<number>(-1);
    const [flatElements, setFlatElements] = useState<IMenuElement[]>([]);
    const selectedRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setFilteredElements(elements);
        setFlatElements(elements.flatMap((el) => el.elements));
        setSelected(0); // Always select the first element
    }, [elements]);

    useEffect(() => {
        setTimeout(() => {
            if (input.current) {
                input.current.focus();
            }
        }, 20);
    }, []);

    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [selected]);

    return (
        <div
            className="flex h-[60vh] w-96 select-none flex-col"
            onClick={() => {
                if (input.current) {
                    input.current.focus();
                }
            }}
        >
            <input
                type="text"
                className="block w-full border-[1px] p-2"
                placeholder="Wyszukaj"
                value={search}
                autoFocus
                ref={input}
                onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                        setSelected((prev) => (prev + 1) % flatElements.length);
                    }
                    if (e.key === "ArrowUp") {
                        setSelected((prev) => (prev - 1 + flatElements.length) % flatElements.length);
                    }
                    if (e.key === "Enter") {
                        if (flatElements[selected]) {
                            NavigateToMenuElement(flatElements[selected], routerMethod);
                            onSelect();
                        }
                    }
                }}
                onChange={(e) => {
                    setSearch(e.target.value);
                    const filtred = elements
                        .map((el) => {
                            return {
                                ...el,
                                elements: el.elements.filter((el2) => el2.title.toLowerCase().includes(e.target.value.toLowerCase())),
                            };
                        })
                        .filter((el) => el.elements.length > 0);
                    setFilteredElements(filtred);
                    setFlatElements(filtred.flatMap((el) => el.elements));
                    setSelected(0); // Always select the first element
                }}
            />
            <div className="grow overflow-auto">
                {filteredElements.map((el) => {
                    const Icon = el.icon ?? AArrowDown;
                    return (
                        <div key={el.title}>
                            <div className="bg-neutral-200 p-1">
                                <Icon className="h-[1em] w-[1em]" /> {el.title}
                            </div>
                            {el.elements.map((el2) => {
                                const isSelected = selected === flatElements.indexOf(el2);
                                return (
                                    <div
                                        key={el2.title}
                                        ref={isSelected ? selectedRef : null}
                                        className={`pl-4 hover:cursor-pointer hover:bg-neutral-200 ${isSelected ? "bg-neutral-300 text-red-700" : ""}`}
                                        onClick={() => {
                                            NavigateToMenuElement(el2, routerMethod);
                                            onSelect();
                                        }}
                                    >
                                        {el2.title}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export { LayerMenu };
