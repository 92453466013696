// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcMonitor.proto" (package "grpcMonitor", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Track } from "./grpcMonitor";
import type { Empty } from "./google/protobuf/empty";
import type { MonitorInputMessage } from "./grpcMonitor";
import type { ClientStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { MonitorMessage } from "./grpcMonitor";
import type { TaskMonitorQuery } from "./grpcMonitor";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcMonitor.Track
 */
export interface ITrackClient {
    /**
     * @generated from protobuf rpc: GetTaskMonitorStream(grpcMonitor.TaskMonitorQuery) returns (stream grpcMonitor.MonitorMessage);
     */
    getTaskMonitorStream(input: TaskMonitorQuery, options?: RpcOptions): ServerStreamingCall<TaskMonitorQuery, MonitorMessage>;
    /**
     * @generated from protobuf rpc: TrackTask(stream grpcMonitor.MonitorInputMessage) returns (google.protobuf.Empty);
     */
    trackTask(options?: RpcOptions): ClientStreamingCall<MonitorInputMessage, Empty>;
}
/**
 * @generated from protobuf service grpcMonitor.Track
 */
export class TrackClient implements ITrackClient, ServiceInfo {
    typeName = Track.typeName;
    methods = Track.methods;
    options = Track.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetTaskMonitorStream(grpcMonitor.TaskMonitorQuery) returns (stream grpcMonitor.MonitorMessage);
     */
    getTaskMonitorStream(input: TaskMonitorQuery, options?: RpcOptions): ServerStreamingCall<TaskMonitorQuery, MonitorMessage> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<TaskMonitorQuery, MonitorMessage>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TrackTask(stream grpcMonitor.MonitorInputMessage) returns (google.protobuf.Empty);
     */
    trackTask(options?: RpcOptions): ClientStreamingCall<MonitorInputMessage, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<MonitorInputMessage, Empty>("clientStreaming", this._transport, method, opt);
    }
}
