import { getTransport } from '../configureService';
import { MessageType } from "@protobuf-ts/runtime";
import { ConfigKey } from "@as-pl/env/src/getEnv";
import {ComplainsServiceClient as ErpBackendComplainsService} from './erp-backend/grpcComplains.client';
import {ExportsServiceClient as ErpBackendExportsService} from './erp-backend/grpcExports_service.client';
import {ProducersServiceClient as ErpBackendProducersService} from './erp-backend/grpcProducers.client';
import {ProductEditServiceClient as ErpBackendProductEditService} from './erp-backend/grpcProduct_tecodc.client';
import {ProductImageExporterServiceClient as ErpBackendProductImageExporterService} from './erp-backend/grpcProductimageexporter.client';
import {ProfilesServiceClient as ErpBackendProfilesService} from './erp-backend/grpcProfiles_service.client';
import {TecdocServiceClient as ErpBackendTecdocService} from './erp-backend/grpcTecdoc_service.client';
import {TodoServiceClient as ErpBackendTodoService} from './erp-backend/grpcTodo_service.client';
import {QueueServiceClient as QueueManagerQueueService} from './queue-manager/grpcServices.client';
import {ScheduleServiceClient as QueueManagerScheduleService} from './queue-manager/grpcServices.client';
import {AssetsServiceClient as QueueManagerAssetsService} from './queue-manager/grpcServices.client';
import {QueueServiceTaskChainClient as QueueManagerQueueServiceTaskChain} from './queue-manager/grpcServices.client';
import {TrackClient as TaskMonitorTrack} from './task-monitor/grpcMonitor.client';


type ExtractGeneric<T> = T extends MessageType<infer U> ? U : never;


import  * as ErpBackendComplainsServiceTypesImport from './erp-backend/grpcComplains';
type ListColumnValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListColumnValue>; 
type FilterErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Filter>; 
type ListOrderValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListOrderValue>; 
type MonitorMessageErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MonitorMessage>; 
type ColumnErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Column>; 
type ListFilterValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListFilterValue>; 
type OrderErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Order>; 
type MapStringStringValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MapStringStringValue>; 
type DataQueryErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.DataQuery>; 
import  * as ErpBackendExportsServiceTypesImport from './erp-backend/grpcExports_service';
type TestConnErpBackendExportsServiceType = ExtractGeneric<typeof ErpBackendExportsServiceTypesImport.TestConn>; 
import  * as ErpBackendProducersServiceTypesImport from './erp-backend/grpcProducers';
type ListSortModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListSortModelValue>; 
type ListStringValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListStringValue>; 
type ListProducerValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListProducerValue>; 
type NullInt32ErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.NullInt32>; 
type FilterModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterModel>; 
type GetProducersRowErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.GetProducersRow>; 
type SortModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.SortModel>; 
type ListFilterConditionClValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterConditionClValue>; 
type FilterConditionClErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterConditionCl>; 
type FilterConditionErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterCondition>; 
type ListFilterModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterModelValue>; 
type ProducerErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.Producer>; 
type ProducersGridResultErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ProducersGridResult>; 
type AddCNInputErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AddCNInput>; 
type AgGridQueryDataErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AgGridQueryData>; 
import  * as ErpBackendProductEditServiceTypesImport from './erp-backend/grpcProduct_tecodc';
type QueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.QueryData>; 
type GetEnginesRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesRow>; 
type EngineResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.EngineResult>; 
type ListIntValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIntValue>; 
type InsertManyAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.InsertManyAppsInput>; 
type IN_QueryData_OrderErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Order>; 
type ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_Filters_ValueValue>; 
type NullStringErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.NullString>; 
type ListManySearchResultValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListManySearchResultValue>; 
type ListIN_QueryData_OrderValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_OrderValue>; 
type ListGetLinkedCrossListRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetLinkedCrossListRowValue>; 
type GetLinkedCrossListRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrossListRow>; 
type ManySearchResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ManySearchResult>; 
type GetAppGlobalInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetAppGlobalInput>; 
type FilterModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterModel>; 
type ApplicationRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationRow>; 
type ApplicationHistoryOutputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationHistoryOutput>; 
type ListGetEnginesRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetEnginesRowValue>; 
type NullInt32ErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.NullInt32>; 
type SortModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SortModel>; 
type IN_QueryData_Filters_ValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters_Value>; 
type ApplicationHistoryInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationHistoryInput>; 
type SearchDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchData>; 
type ListFilterConditionClValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterConditionClValue>; 
type ListSearchForApplicationRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchForApplicationRowValue>; 
type ListFilterModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterModelValue>; 
type ListApplicationHistoryOutputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListApplicationHistoryOutputValue>; 
type GetAppListInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetAppListInput>; 
type GetLinkedCrossInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrossInput>; 
type SearchDataInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchDataInput>; 
type MergeInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.MergeInput>; 
type ListInsertManyAppsInputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListInsertManyAppsInputValue>; 
type ListIN_QueryData_FiltersValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_FiltersValue>; 
type IN_QueryData_FiltersErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters>; 
type GetEnginesInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesInput>; 
type LinkAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.LinkAppsInput>; 
type ListSearchDataValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchDataValue>; 
type SearchForManyApplicationInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchForManyApplicationInput>; 
type FilterConditionClErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterConditionCl>; 
type FilterConditionErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterCondition>; 
type AgGridQueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.AgGridQueryData>; 
type ListStringValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListStringValue>; 
type ListApplicationRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListApplicationRowValue>; 
type ApplicationResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationResult>; 
type SearchForApplicationRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchForApplicationRow>; 
type ListSortModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSortModelValue>; 
import  * as ErpBackendProductImageExporterServiceTypesImport from './erp-backend/grpcProductimageexporter';
type InputErpBackendProductImageExporterServiceType = ExtractGeneric<typeof ErpBackendProductImageExporterServiceTypesImport.Input>; 
import  * as ErpBackendProfilesServiceTypesImport from './erp-backend/grpcProfiles_service';
type ListStringValueErpBackendProfilesServiceType = ExtractGeneric<typeof ErpBackendProfilesServiceTypesImport.ListStringValue>; 
import  * as ErpBackendTecdocServiceTypesImport from './erp-backend/grpcTecdoc_service';
type AuthDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.AuthData>; 
type MetaDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MetaData>; 
type BaseErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.Base>; 
type TecDocApplicationImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocApplicationImporterMessage>; 
type TecDocImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocImporterMessage>; 
type MonitorMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MonitorMessage>; 
import  * as ErpBackendTodoServiceTypesImport from './erp-backend/grpcTodo_service';
type GetListsRowErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.GetListsRow>; 
type MapStringStringValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.MapStringStringValue>; 
type NullStringErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.NullString>; 
type ListGetTodoTasksRowValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.ListGetTodoTasksRowValue>; 
type GetTodoTasksRowErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.GetTodoTasksRow>; 
type ListGetListsRowValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.ListGetListsRowValue>; 

import  * as QueueManagerQueueServiceTypesImport from './queue-manager/grpcServices';
type ListJobInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListJobInfoValue>; 
type GetTasksListInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetTasksListInput>; 
type DurationQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Duration>; 
type TaskStreamMessageQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskStreamMessage>; 
type ListSortsValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListSortsValue>; 
type ListDirInfoStructValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListDirInfoStructValue>; 
type CronNextInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.CronNextInput>; 
type ListByteValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListByteValue>; 
type ListFiltersValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListFiltersValue>; 
type QueryDataQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueryData>; 
type SchedulerTableQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.SchedulerTable>; 
type MapStringQueueDescriptionValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.MapStringQueueDescriptionValue>; 
type ListTaskDefValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ChainLinkDef>; 
type TaskInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskInfo>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerQueueInfoValue>; 
type ListSchedulerTableValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListSchedulerTableValue>; 
type DirInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfo>; 
type QueueDescriptionQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueDescription>; 
type ListStringValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListStringValue>; 
type TaskStateQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskState>; 
type GetJobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetJobInput>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListGetQueueMessRowValue>; 
type JobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobInput>; 
type SortsQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Sorts>; 
type RunJobInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.RunJobInput>; 
type ListChainLinkDefValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListChainLinkDefValue>; 
type QueueInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueInfo>; 
type CancelTaskInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.CancelTaskInput>; 
type JobInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobInfo>; 
type ChainDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ChainDef>; 
type GetQueueMessInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessInput>; 
type DirInfoStructQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfoStruct>; 
type QueueServerInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueServerInfo>; 
type TaskDefQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskDef>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerTaskInfoValue>; 
type GetQueueMessRowQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessRow>; 
type FiltersQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.Filters>; 
type SchedulerTableResultQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.SchedulerTableResult>; 
type JobListQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.JobList>; 
import  * as QueueManagerScheduleServiceTypesImport from './queue-manager/grpcServices';
type ListJobInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListJobInfoValue>; 
type GetTasksListInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetTasksListInput>; 
type DurationQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Duration>; 
type TaskStreamMessageQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskStreamMessage>; 
type ListSortsValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListSortsValue>; 
type ListDirInfoStructValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListDirInfoStructValue>; 
type CronNextInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.CronNextInput>; 
type ListByteValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListByteValue>; 
type ListFiltersValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListFiltersValue>; 
type QueryDataQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueryData>; 
type SchedulerTableQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.SchedulerTable>; 
type MapStringQueueDescriptionValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.MapStringQueueDescriptionValue>; 
type ListTaskDefValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ChainLinkDef>; 
type TaskInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskInfo>; 
type ListPointerQueueInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListPointerQueueInfoValue>; 
type ListSchedulerTableValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListSchedulerTableValue>; 
type DirInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.DirInfo>; 
type QueueDescriptionQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueDescription>; 
type ListStringValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListStringValue>; 
type TaskStateQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskState>; 
type GetJobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetJobInput>; 
type ListGetQueueMessRowValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListGetQueueMessRowValue>; 
type JobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobInput>; 
type SortsQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Sorts>; 
type RunJobInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.RunJobInput>; 
type ListChainLinkDefValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListChainLinkDefValue>; 
type QueueInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueInfo>; 
type CancelTaskInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.CancelTaskInput>; 
type JobInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobInfo>; 
type ChainDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ChainDef>; 
type GetQueueMessInputQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetQueueMessInput>; 
type DirInfoStructQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.DirInfoStruct>; 
type QueueServerInfoQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.QueueServerInfo>; 
type TaskDefQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.TaskDef>; 
type ListPointerTaskInfoValueQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.ListPointerTaskInfoValue>; 
type GetQueueMessRowQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.GetQueueMessRow>; 
type FiltersQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.Filters>; 
type SchedulerTableResultQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.SchedulerTableResult>; 
type JobListQueueManagerScheduleServiceType = ExtractGeneric<typeof QueueManagerScheduleServiceTypesImport.JobList>; 
import  * as QueueManagerAssetsServiceTypesImport from './queue-manager/grpcServices';
type ListJobInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListJobInfoValue>; 
type GetTasksListInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetTasksListInput>; 
type DurationQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Duration>; 
type TaskStreamMessageQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskStreamMessage>; 
type ListSortsValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListSortsValue>; 
type ListDirInfoStructValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListDirInfoStructValue>; 
type CronNextInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.CronNextInput>; 
type ListByteValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListByteValue>; 
type ListFiltersValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListFiltersValue>; 
type QueryDataQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueryData>; 
type SchedulerTableQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.SchedulerTable>; 
type MapStringQueueDescriptionValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.MapStringQueueDescriptionValue>; 
type ListTaskDefValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ChainLinkDef>; 
type TaskInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskInfo>; 
type ListPointerQueueInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerQueueInfoValue>; 
type ListSchedulerTableValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListSchedulerTableValue>; 
type DirInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfo>; 
type QueueDescriptionQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueDescription>; 
type ListStringValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListStringValue>; 
type TaskStateQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskState>; 
type GetJobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetJobInput>; 
type ListGetQueueMessRowValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListGetQueueMessRowValue>; 
type JobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobInput>; 
type SortsQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Sorts>; 
type RunJobInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.RunJobInput>; 
type ListChainLinkDefValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListChainLinkDefValue>; 
type QueueInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueInfo>; 
type CancelTaskInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.CancelTaskInput>; 
type JobInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobInfo>; 
type ChainDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ChainDef>; 
type GetQueueMessInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessInput>; 
type DirInfoStructQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfoStruct>; 
type QueueServerInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueServerInfo>; 
type TaskDefQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskDef>; 
type ListPointerTaskInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerTaskInfoValue>; 
type GetQueueMessRowQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessRow>; 
type FiltersQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.Filters>; 
type SchedulerTableResultQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.SchedulerTableResult>; 
type JobListQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.JobList>; 
import  * as QueueManagerQueueServiceTaskChainTypesImport from './queue-manager/grpcServices';
type ListJobInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListJobInfoValue>; 
type GetTasksListInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetTasksListInput>; 
type DurationQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Duration>; 
type TaskStreamMessageQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskStreamMessage>; 
type ListSortsValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListSortsValue>; 
type ListDirInfoStructValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListDirInfoStructValue>; 
type CronNextInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.CronNextInput>; 
type ListByteValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListByteValue>; 
type ListFiltersValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListFiltersValue>; 
type QueryDataQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueryData>; 
type SchedulerTableQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.SchedulerTable>; 
type MapStringQueueDescriptionValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.MapStringQueueDescriptionValue>; 
type ListTaskDefValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListTaskDefValue>; 
type ChainLinkDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ChainLinkDef>; 
type TaskInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskInfo>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerQueueInfoValue>; 
type ListSchedulerTableValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListSchedulerTableValue>; 
type DirInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfo>; 
type QueueDescriptionQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueDescription>; 
type ListStringValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListStringValue>; 
type TaskStateQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskState>; 
type GetJobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetJobInput>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListGetQueueMessRowValue>; 
type JobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobInput>; 
type SortsQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Sorts>; 
type RunJobInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.RunJobInput>; 
type ListChainLinkDefValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListChainLinkDefValue>; 
type QueueInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueInfo>; 
type CancelTaskInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.CancelTaskInput>; 
type JobInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobInfo>; 
type ChainDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ChainDef>; 
type GetQueueMessInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessInput>; 
type DirInfoStructQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfoStruct>; 
type QueueServerInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueServerInfo>; 
type TaskDefQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskDef>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerTaskInfoValue>; 
type GetQueueMessRowQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessRow>; 
type FiltersQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.Filters>; 
type SchedulerTableResultQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.SchedulerTableResult>; 
type JobListQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.JobList>; 

import  * as TaskMonitorTrackTypesImport from './task-monitor/grpcMonitor';
type TaskMonitorQueryTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.TaskMonitorQuery>; 
type MonitorMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorMessage>; 
type MonitorInputMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorInputMessage>; 


const transports = {
  ErpBackend: getTransport( ConfigKey.NEXT_PUBLIC_BACKEND ),
  QueueManager: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  TaskMonitor: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  }


export class GrpcManager {
static  ErpBackend= {
  getComplainsService(){
 return new ErpBackendComplainsService(transports.ErpBackend)  },
  getExportsService(){
 return new ErpBackendExportsService(transports.ErpBackend)  },
  getProducersService(){
 return new ErpBackendProducersService(transports.ErpBackend)  },
  getProductEditService(){
 return new ErpBackendProductEditService(transports.ErpBackend)  },
  getProductImageExporterService(){
 return new ErpBackendProductImageExporterService(transports.ErpBackend)  },
  getProfilesService(){
 return new ErpBackendProfilesService(transports.ErpBackend)  },
  getTecdocService(){
 return new ErpBackendTecdocService(transports.ErpBackend)  },
  getTodoService(){
 return new ErpBackendTodoService(transports.ErpBackend)  },
}
static  QueueManager= {
  getQueueService(){
 return new QueueManagerQueueService(transports.QueueManager)  },
  getScheduleService(){
 return new QueueManagerScheduleService(transports.QueueManager)  },
  getAssetsService(){
 return new QueueManagerAssetsService(transports.QueueManager)  },
  getQueueServiceTaskChain(){
 return new QueueManagerQueueServiceTaskChain(transports.QueueManager)  },
}
static  TaskMonitor= {
  getTrack(){
 return new TaskMonitorTrack(transports.TaskMonitor)  },
}
}


export namespace Types{
	export namespace ErpBackend{
		export namespace ComplainsService{
			export type  ListColumnValue=ListColumnValueErpBackendComplainsServiceType;
 			export type  Filter=FilterErpBackendComplainsServiceType;
 			export type  ListOrderValue=ListOrderValueErpBackendComplainsServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendComplainsServiceType;
 			export type  Column=ColumnErpBackendComplainsServiceType;
 			export type  ListFilterValue=ListFilterValueErpBackendComplainsServiceType;
 			export type  Order=OrderErpBackendComplainsServiceType;
 			export type  MapStringStringValue=MapStringStringValueErpBackendComplainsServiceType;
 			export type  DataQuery=DataQueryErpBackendComplainsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ExportsService{
			export type  TestConn=TestConnErpBackendExportsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProducersService{
			export type  ListSortModelValue=ListSortModelValueErpBackendProducersServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProducersServiceType;
 			export type  ListProducerValue=ListProducerValueErpBackendProducersServiceType;
 			export type  NullInt32=NullInt32ErpBackendProducersServiceType;
 			export type  FilterModel=FilterModelErpBackendProducersServiceType;
 			export type  GetProducersRow=GetProducersRowErpBackendProducersServiceType;
 			export type  SortModel=SortModelErpBackendProducersServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProducersServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProducersServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProducersServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProducersServiceType;
 			export type  Producer=ProducerErpBackendProducersServiceType;
 			export type  ProducersGridResult=ProducersGridResultErpBackendProducersServiceType;
 			export type  AddCNInput=AddCNInputErpBackendProducersServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendProducersServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductEditService{
			export type  QueryData=QueryDataErpBackendProductEditServiceType;
 			export type  GetEnginesRow=GetEnginesRowErpBackendProductEditServiceType;
 			export type  EngineResult=EngineResultErpBackendProductEditServiceType;
 			export type  ListIntValue=ListIntValueErpBackendProductEditServiceType;
 			export type  InsertManyAppsInput=InsertManyAppsInputErpBackendProductEditServiceType;
 			export type  IN_QueryData_Order=IN_QueryData_OrderErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_Filters_ValueValue=ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType;
 			export type  NullString=NullStringErpBackendProductEditServiceType;
 			export type  ListManySearchResultValue=ListManySearchResultValueErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_OrderValue=ListIN_QueryData_OrderValueErpBackendProductEditServiceType;
 			export type  ListGetLinkedCrossListRowValue=ListGetLinkedCrossListRowValueErpBackendProductEditServiceType;
 			export type  GetLinkedCrossListRow=GetLinkedCrossListRowErpBackendProductEditServiceType;
 			export type  ManySearchResult=ManySearchResultErpBackendProductEditServiceType;
 			export type  GetAppGlobalInput=GetAppGlobalInputErpBackendProductEditServiceType;
 			export type  FilterModel=FilterModelErpBackendProductEditServiceType;
 			export type  ApplicationRow=ApplicationRowErpBackendProductEditServiceType;
 			export type  ApplicationHistoryOutput=ApplicationHistoryOutputErpBackendProductEditServiceType;
 			export type  ListGetEnginesRowValue=ListGetEnginesRowValueErpBackendProductEditServiceType;
 			export type  NullInt32=NullInt32ErpBackendProductEditServiceType;
 			export type  SortModel=SortModelErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters_Value=IN_QueryData_Filters_ValueErpBackendProductEditServiceType;
 			export type  ApplicationHistoryInput=ApplicationHistoryInputErpBackendProductEditServiceType;
 			export type  SearchData=SearchDataErpBackendProductEditServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProductEditServiceType;
 			export type  ListSearchForApplicationRowValue=ListSearchForApplicationRowValueErpBackendProductEditServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProductEditServiceType;
 			export type  ListApplicationHistoryOutputValue=ListApplicationHistoryOutputValueErpBackendProductEditServiceType;
 			export type  GetAppListInput=GetAppListInputErpBackendProductEditServiceType;
 			export type  GetLinkedCrossInput=GetLinkedCrossInputErpBackendProductEditServiceType;
 			export type  SearchDataInput=SearchDataInputErpBackendProductEditServiceType;
 			export type  MergeInput=MergeInputErpBackendProductEditServiceType;
 			export type  ListInsertManyAppsInputValue=ListInsertManyAppsInputValueErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_FiltersValue=ListIN_QueryData_FiltersValueErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters=IN_QueryData_FiltersErpBackendProductEditServiceType;
 			export type  GetEnginesInput=GetEnginesInputErpBackendProductEditServiceType;
 			export type  LinkAppsInput=LinkAppsInputErpBackendProductEditServiceType;
 			export type  ListSearchDataValue=ListSearchDataValueErpBackendProductEditServiceType;
 			export type  SearchForManyApplicationInput=SearchForManyApplicationInputErpBackendProductEditServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProductEditServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProductEditServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendProductEditServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProductEditServiceType;
 			export type  ListApplicationRowValue=ListApplicationRowValueErpBackendProductEditServiceType;
 			export type  ApplicationResult=ApplicationResultErpBackendProductEditServiceType;
 			export type  SearchForApplicationRow=SearchForApplicationRowErpBackendProductEditServiceType;
 			export type  ListSortModelValue=ListSortModelValueErpBackendProductEditServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductImageExporterService{
			export type  Input=InputErpBackendProductImageExporterServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProfilesService{
			export type  ListStringValue=ListStringValueErpBackendProfilesServiceType;
 }
}
	export namespace ErpBackend{
		export namespace TecdocService{
			export type  AuthData=AuthDataErpBackendTecdocServiceType;
 			export type  MetaData=MetaDataErpBackendTecdocServiceType;
 			export type  Base=BaseErpBackendTecdocServiceType;
 			export type  TecDocApplicationImporterMessage=TecDocApplicationImporterMessageErpBackendTecdocServiceType;
 			export type  TecDocImporterMessage=TecDocImporterMessageErpBackendTecdocServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendTecdocServiceType;
 }
}
	export namespace ErpBackend{
		export namespace TodoService{
			export type  GetListsRow=GetListsRowErpBackendTodoServiceType;
 			export type  MapStringStringValue=MapStringStringValueErpBackendTodoServiceType;
 			export type  NullString=NullStringErpBackendTodoServiceType;
 			export type  ListGetTodoTasksRowValue=ListGetTodoTasksRowValueErpBackendTodoServiceType;
 			export type  GetTodoTasksRow=GetTodoTasksRowErpBackendTodoServiceType;
 			export type  ListGetListsRowValue=ListGetListsRowValueErpBackendTodoServiceType;
 }
}
	export namespace QueueManager{
		export namespace QueueService{
			export type  ListJobInfoValue=ListJobInfoValueQueueManagerQueueServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceType;
 			export type  Duration=DurationQueueManagerQueueServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerQueueServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerQueueServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerQueueServiceType;
 			export type  QueryData=QueryDataQueueManagerQueueServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerQueueServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerQueueServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerQueueServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerQueueServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerQueueServiceType;
 			export type  DirInfo=DirInfoQueueManagerQueueServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerQueueServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceType;
 			export type  TaskState=TaskStateQueueManagerQueueServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerQueueServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceType;
 			export type  JobInput=JobInputQueueManagerQueueServiceType;
 			export type  Sorts=SortsQueueManagerQueueServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerQueueServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerQueueServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceType;
 			export type  JobInfo=JobInfoQueueManagerQueueServiceType;
 			export type  ChainDef=ChainDefQueueManagerQueueServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceType;
 			export type  TaskDef=TaskDefQueueManagerQueueServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceType;
 			export type  Filters=FiltersQueueManagerQueueServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerQueueServiceType;
 			export type  JobList=JobListQueueManagerQueueServiceType;
 }
		export namespace ScheduleService{
			export type  ListJobInfoValue=ListJobInfoValueQueueManagerScheduleServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerScheduleServiceType;
 			export type  Duration=DurationQueueManagerScheduleServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerScheduleServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerScheduleServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerScheduleServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerScheduleServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerScheduleServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerScheduleServiceType;
 			export type  QueryData=QueryDataQueueManagerScheduleServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerScheduleServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerScheduleServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerScheduleServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerScheduleServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerScheduleServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerScheduleServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerScheduleServiceType;
 			export type  DirInfo=DirInfoQueueManagerScheduleServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerScheduleServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerScheduleServiceType;
 			export type  TaskState=TaskStateQueueManagerScheduleServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerScheduleServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerScheduleServiceType;
 			export type  JobInput=JobInputQueueManagerScheduleServiceType;
 			export type  Sorts=SortsQueueManagerScheduleServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerScheduleServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerScheduleServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerScheduleServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerScheduleServiceType;
 			export type  JobInfo=JobInfoQueueManagerScheduleServiceType;
 			export type  ChainDef=ChainDefQueueManagerScheduleServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerScheduleServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerScheduleServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerScheduleServiceType;
 			export type  TaskDef=TaskDefQueueManagerScheduleServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerScheduleServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerScheduleServiceType;
 			export type  Filters=FiltersQueueManagerScheduleServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerScheduleServiceType;
 			export type  JobList=JobListQueueManagerScheduleServiceType;
 }
		export namespace AssetsService{
			export type  ListJobInfoValue=ListJobInfoValueQueueManagerAssetsServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerAssetsServiceType;
 			export type  Duration=DurationQueueManagerAssetsServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerAssetsServiceType;
 			export type  ListSortsValue=ListSortsValueQueueManagerAssetsServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerAssetsServiceType;
 			export type  CronNextInput=CronNextInputQueueManagerAssetsServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerAssetsServiceType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerAssetsServiceType;
 			export type  QueryData=QueryDataQueueManagerAssetsServiceType;
 			export type  SchedulerTable=SchedulerTableQueueManagerAssetsServiceType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerAssetsServiceType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerAssetsServiceType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerAssetsServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerAssetsServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerAssetsServiceType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerAssetsServiceType;
 			export type  DirInfo=DirInfoQueueManagerAssetsServiceType;
 			export type  QueueDescription=QueueDescriptionQueueManagerAssetsServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerAssetsServiceType;
 			export type  TaskState=TaskStateQueueManagerAssetsServiceType;
 			export type  GetJobInput=GetJobInputQueueManagerAssetsServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerAssetsServiceType;
 			export type  JobInput=JobInputQueueManagerAssetsServiceType;
 			export type  Sorts=SortsQueueManagerAssetsServiceType;
 			export type  RunJobInput=RunJobInputQueueManagerAssetsServiceType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerAssetsServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerAssetsServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerAssetsServiceType;
 			export type  JobInfo=JobInfoQueueManagerAssetsServiceType;
 			export type  ChainDef=ChainDefQueueManagerAssetsServiceType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerAssetsServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerAssetsServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerAssetsServiceType;
 			export type  TaskDef=TaskDefQueueManagerAssetsServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerAssetsServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerAssetsServiceType;
 			export type  Filters=FiltersQueueManagerAssetsServiceType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerAssetsServiceType;
 			export type  JobList=JobListQueueManagerAssetsServiceType;
 }
		export namespace QueueServiceTaskChain{
			export type  ListJobInfoValue=ListJobInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceTaskChainType;
 			export type  Duration=DurationQueueManagerQueueServiceTaskChainType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceTaskChainType;
 			export type  ListSortsValue=ListSortsValueQueueManagerQueueServiceTaskChainType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceTaskChainType;
 			export type  CronNextInput=CronNextInputQueueManagerQueueServiceTaskChainType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceTaskChainType;
 			export type  ListFiltersValue=ListFiltersValueQueueManagerQueueServiceTaskChainType;
 			export type  QueryData=QueryDataQueueManagerQueueServiceTaskChainType;
 			export type  SchedulerTable=SchedulerTableQueueManagerQueueServiceTaskChainType;
 			export type  MapStringQueueDescriptionValue=MapStringQueueDescriptionValueQueueManagerQueueServiceTaskChainType;
 			export type  ListTaskDefValue=ListTaskDefValueQueueManagerQueueServiceTaskChainType;
 			export type  ChainLinkDef=ChainLinkDefQueueManagerQueueServiceTaskChainType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  ListSchedulerTableValue=ListSchedulerTableValueQueueManagerQueueServiceTaskChainType;
 			export type  DirInfo=DirInfoQueueManagerQueueServiceTaskChainType;
 			export type  QueueDescription=QueueDescriptionQueueManagerQueueServiceTaskChainType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceTaskChainType;
 			export type  TaskState=TaskStateQueueManagerQueueServiceTaskChainType;
 			export type  GetJobInput=GetJobInputQueueManagerQueueServiceTaskChainType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType;
 			export type  JobInput=JobInputQueueManagerQueueServiceTaskChainType;
 			export type  Sorts=SortsQueueManagerQueueServiceTaskChainType;
 			export type  RunJobInput=RunJobInputQueueManagerQueueServiceTaskChainType;
 			export type  ListChainLinkDefValue=ListChainLinkDefValueQueueManagerQueueServiceTaskChainType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceTaskChainType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceTaskChainType;
 			export type  JobInfo=JobInfoQueueManagerQueueServiceTaskChainType;
 			export type  ChainDef=ChainDefQueueManagerQueueServiceTaskChainType;
 			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceTaskChainType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceTaskChainType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceTaskChainType;
 			export type  TaskDef=TaskDefQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceTaskChainType;
 			export type  Filters=FiltersQueueManagerQueueServiceTaskChainType;
 			export type  SchedulerTableResult=SchedulerTableResultQueueManagerQueueServiceTaskChainType;
 			export type  JobList=JobListQueueManagerQueueServiceTaskChainType;
 }
}
	export namespace TaskMonitor{
		export namespace Track{
			export type  TaskMonitorQuery=TaskMonitorQueryTaskMonitorTrackType;
 			export type  MonitorMessage=MonitorMessageTaskMonitorTrackType;
 			export type  MonitorInputMessage=MonitorInputMessageTaskMonitorTrackType;
 }
}
}
