import React, { useState, useEffect, useRef } from "react";

interface cronType {
    minutes: string;
    hour: string;
    day: string;
    month: string;
    weekday: string;
}

const CronField = ({ value, type, onChange }: { value: string; type: keyof cronType; onChange: (type: keyof cronType, value: string) => void }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(type, e.target.value);

    return (
        <span className="flex h-9 w-12 items-center justify-center overflow-hidden border-[1px] bg-white">
            <input type="text" value={value} onChange={handleChange} className="h-full w-full !border-none !p-0 text-center !outline-none" />
        </span>
    );
};

export const CronBoxes = ({ cronString, onChange }: { cronString: string; onChange: (str: string) => void }) => {
    const [cron, setCron] = useState<cronType>({
        minutes: "*",
        hour: "*",
        day: "*",
        month: "*",
        weekday: "*",
    });

    const mounted = useRef(false);

    useEffect(() => {
        if (cronString) {
            setCron(transformType(cronString));
        }
    }, [cronString]);

    const handleCronChange = (type: keyof cronType, value: string) => {
        setCron((prevCron) => {
            const tmp = {
                ...prevCron,
                [type]: value ?? "*",
            };
            onChange(transformToString(tmp));
            return tmp;
        });
        mounted.current = true;
    };

    const transformType = (str: string): cronType => {
        const cronSplit = str.split(" ");
        return {
            minutes: cronSplit[0] ?? "*",
            hour: cronSplit[1] ?? "*",
            day: cronSplit[2] ?? "*",
            month: cronSplit[3] ?? "*",
            weekday: cronSplit[4] ?? "*",
        };
    };

    const transformToString = (cron: cronType): string => {
        return `${cron.minutes} ${cron.hour} ${cron.day} ${cron.month} ${cron.weekday}`;
    };

    return (
        <div className="inline-flex items-center gap-1.5">
            <CronField value={cron?.minutes} type="minutes" onChange={handleCronChange} />
            <CronField value={cron?.hour} type="hour" onChange={handleCronChange} />
            <CronField value={cron?.day} type="day" onChange={handleCronChange} />
            <CronField value={cron?.month} type="month" onChange={handleCronChange} />
            <CronField value={cron?.weekday} type="weekday" onChange={handleCronChange} />
        </div>
    );
};
