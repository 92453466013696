import { X } from "lucide-react";
import { SelectTask } from "./SelectTask";
import { Types } from "grpc-connect";

type TChainLink = Types.QueueManager.QueueServiceTaskChain.ChainLinkDef;

export const Chain = ({
    id,
    updateTask,
    chainLink,
    deleteFn,
    options,
}: {
    id: number;
    updateTask: (chainLink: TChainLink, id: number) => void;
    chainLink: TChainLink;
    deleteFn: (index: number) => void;
    options: string[];
}) => {
    const updateWrapper = (opt: string, input: string, index: number) => {
        const tmp = [...chainLink.Tasks];
        if (opt.length > 0 && tmp[index]) {
            tmp[index].QueueName = opt;
        }
        if (input.length > 0 && tmp[index]) {
            tmp[index].MsgConfig = input;
        }

        updateTask(
            {
                Tasks: tmp,
            },
            id,
        );
    };

    const addWrapper = () => {
        updateTask(
            {
                Tasks: [...chainLink.Tasks, { QueueName: "", MsgConfig: "" }],
            },
            id,
        );
    };

    const deleteWrapper = (x: number) => {
        updateTask(
            {
                Tasks: [...chainLink.Tasks.filter((_, index) => x != index)],
            },
            id,
        );
    };

    return (
        <div className="relative flex h-fit w-full flex-col gap-2 border-2 border-gray-300 p-2">
            <button className="absolute right-2 top-1 hover:text-red-600" onClick={() => deleteFn(id)}>
                <X size={20} />
            </button>
            <b>
                <h4 className="text-center">Ogniwo - {id + 1}</h4>
            </b>
            <button className="btn" onClick={addWrapper}>
                Dodaj Zadanie
            </button>
            <div className="flex flex-col gap-6">
                {chainLink.Tasks.map((t, index) => {
                    return (
                        <div key={index} className="flex items-center justify-center gap-2">
                            <SelectTask
                                sendOption={(opt) => updateWrapper(opt, "", index)}
                                sendInput={(i) => updateWrapper("", i, index)}
                                options={options}
                                opt={t.QueueName.length > 0 ? t.QueueName : ""}
                                input={t.MsgConfig.length > 0 ? t.MsgConfig : ""}
                            />
                            <button onClick={() => deleteWrapper(index)} className="btn btn-danger">
                                Usuń
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
